<template>
  <div class="metree-box cs-width-p100 cs-height-p100 cs-flex-col-ss">
    <div class="cs-flex-col-ss cs-width-p100 dttablelist">
      <div class="cs-flex-bc cs-margin-bottom-10 cs-width-p100 dttablelist-filter">
        <div class="cs-flex-sc dt-form-box">
          <div class="cs-flex-bc dt-form-form cs-margin-right-10">
            <div class="cs-flex-sc cs-flex-wrap dt-form-form-wrap nomore">
              <div class="form-icon-user">
                <img src="@/assets/imgs/tree/other.png" />
              </div>
              <div class="dt-form-item">
                <el-select class="dt-base-select" v-model="formData.type" placeholder="请选择单位类型" @change="deptChange">
                  <el-option v-for="item in deptOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="form-icon-right cs-flex">
                <img src="@/assets/imgs/layout/tree-right.png" />
              </div>
              <div class="dt-form-item">
                <el-select class="dt-base-select" placeholder="请选择单位" v-model="formData.dept_id" @change="baseDepChange">
                  <el-option v-for="item in baseDeptOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="form-icon-right cs-flex">
                <img src="@/assets/imgs/layout/tree-right.png" />
              </div>
              <div class="dt-form-item">
                <el-select class="dt-base-select" placeholder="请选择人员" v-model="formData.uid" @change="baseUserChange">
                  <el-option v-for="item in baseUserOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="cs-flex dt-base-buttons">
            <el-button memo="重置" @click="resetFn">重置</el-button>
          </div>
        </div>
        <div class="step-wrap">
          <el-input-number v-model="scaleNum" :min="10" :max="200" :step="10" class="step-num" @change="scaleChange">
            <template #suffix>%</template>
          </el-input-number>
        </div>
      </div>
    </div>
    <div class="metree-wrap cs-flex-1">
      <metree ref="metreeRef" @event="handleEvn" :grants="grants"></metree>
    </div>
    <AddPreview ref="addProjectRef" :diaTitle="adddiaTitle" @submitForm="reFreshFn" :diaName='diaName'></AddPreview>
    <UserAuthTable ref="userAuthTableRef" :regionid="region_id"></UserAuthTable>
    <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "baseProject",
  title: "创建项目体系",
});
</script>
<script setup lang="ts">
import * as Toast from "@darrytech/extends/utilities/toastutil";
import {
  inject,
  onMounted,
  reactive,
  ref,
  onBeforeUnmount,
  shallowRef,
  nextTick,
} from "vue";
import metree from "./metree.vue";
import AddPreview from "./addAndEdit.vue";
import UserAuthTable from "./userAuth.vue";
import confirmDia from "@/comps/confirmDia/index.vue";
import { UserInfo, userLogout, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const network: any = inject("$network");
const confirmDiaRef = ref();
const addProjectRef = ref();
const adddiaTitle = ref("新增");
const diaName = ref("名称");
const userAuthTableRef = ref();
const metreeRef = ref();
const region_id = ref();
const grants = ref([]) as any;
const scaleNum = ref(100);
const curJie = reactive({
  id: undefined,
  level: undefined,
  name: undefined,
  pid: undefined,
});
const formData = reactive({
  project_id: undefined, // 当前节点的id
  type: undefined,
  dept_id: undefined,
  uid: undefined,
});
const deptOptions = ref([]) as any;
const baseDeptOption = ref([]) as any;
const baseUserOption = ref([]) as any;
const getDeptOption = () => {
  network.dtsystem.listBaseDict({ group_id: 121 }).then((rsp: any) => {
    deptOptions.value = rsp.rows.map((v) => {
      return {
        label: v.item_name,
        value: v.item_id,
      };
    });
  });
};
const deptChange = (val: any) => {
  network.dteqms
    .listBaseDept({ type: val, pageIndex: 1, pageSize: 9999 })
    .then((rsp: any) => {
      baseDeptOption.value = rsp.rows.map((v) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
    });
  formData.dept_id = undefined;
  formData.uid = undefined;
  metreeRef.value.setIcon();
};
const baseDepChange = (val: any) => {
  network.dteqms
    .listBaseUser({
      type: formData.type,
      dept_id: val,
      pageIndex: 1,
      pageSize: 9999,
    })
    .then((rsp: any) => {
      baseUserOption.value = rsp.rows.map((v) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
    });
  formData.uid = undefined;
  metreeRef.value.setIcon();
};
// 人员change事件
const baseUserChange = (val: any) => {
  metreeRef.value.setIcon(val);
};
// 重置
const resetFn = () => {
  formData.type = undefined;
  formData.dept_id = undefined;
  formData.uid = undefined;
  metreeRef.value.setIcon();
};
const reFreshFn = () => {
  getData();
};

const scaleChange = (val: any) => {
  metreeRef.value.setScale(val);
};
const getData = () => {
  network.dteqms
    .listProjectTree({ project_id: usrinfo.project_id })
    .then((rsp: any) => {
      region_id.value = rsp.pid;
      rsp.expanded = true;
      rsp?.children?.forEach((v) => {
        v.expanded = true;
        v.hover = false;
        if (v.children) treeupdate(v.children);
      });
      if (rsp.id) metreeRef.value.setVal(rsp);
    });
};
const treeupdate = (arr) => {
  arr.forEach((v) => {
    v.expanded = true;
    v.hover = false;
    if (v.children) treeupdate(v.children);
  });
};
const handleEvn = (param: any) => {
  let { type, data } = param;
  Object.assign(
    curJie,
    {
      id: undefined,
      level: undefined,
      name: undefined,
      pid: undefined,
    },
    data
  );
  if (type == "user") {
    userAuthTableRef.value.show(curJie);
  } else if (type == "edit") {
    adddiaTitle.value = data.name + "-" + "编辑" + levelEditTitle(+data.level);
    diaName.value = levelEditTitle(+data.level) + "名称";
    addProjectRef.value.show({ data: curJie, type: "edit" });
  } else if (type == "add") {
    adddiaTitle.value = data.name + "-" + "新增" + levelAddTitle(+data.level);
    diaName.value = levelAddTitle(+data.level) + "名称";
    addProjectRef.value.show({ data: curJie, type: "add" });
  } else if (type == "del") {
    //确认后再删除 deleteBaseProject2
    confirmDiaRef.value.showFn({ msg: "确定删除该数据吗？" });
  }
};
const levelAddTitle = (level: any) => {
  let str: any = "";
  switch (level) {
    case 2:
      str = "项目";
      break;
    case 3:
      str = "分部";
      break;
    case 4:
      str = "监理标段";
      break;
    case 5:
      str = "标段";
      break;
    case 6:
      str = "分项";
      break;
    default:
      str = "";
      break;
  }
  return str;
};
const levelEditTitle = (level: any) => {
  let str: any = "";
  switch (level) {
    case 2:
      str = "工程";
      break;
    case 3:
      str = "项目";
      break;
    case 4:
      str = "分部";
      break;
    case 5:
      str = "监理标段";
      break;
    case 6:
      str = "标段";
      break;
    case 7:
      str = "分项";
      break;
    default:
      str = "";
      break;
  }
  return str;
};
const sureDiaFn = () => {
  network.dteqms.deleteBaseProject2({ id: curJie.id }).then((rsp: any) => {
    Toast.info("删除成功!");
    getData();
  });
};
const setGrants = () => {
  let grantsuser = usrinfo.userGrants && usrinfo.userGrants["125"];
  let curG = [
    { name: "add", desc: "新增" },
    { name: "edit", desc: "编辑" },
    { name: "delete", desc: "删除" },
    { name: "user", desc: "关联用户" },
  ];
  grants.value = curG.filter((item3) => grantsuser.includes(item3.name)) || [];
  metreeRef.value.setGrants(grants.value.map((v) => v.name));
};
onMounted(() => {
  if (usrinfo.token && usrinfo.current_router == "baseProject") {
    setGrants();
    getDeptOption();
    getData();
  }
});
</script>
<style lang="scss" scoped>
.metree-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding: 20px 30px;
  background: url("@/assets/imgs/tree/bg.png") repeat 0 0;
  background-size: 48px 48px;
  position: relative;
  .dttablelist {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }
}
.dttablelist-filter .dt-form-box {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 8px 8px;
  padding: 0 12px;
  .dt-form-form {
    .form-icon-user {
      width: 28px;
      height: 28px;
      padding-right: 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form-icon-right {
      width: 5px;
      height: 13px;
      padding: 0 5px;
      img {
        width: 5px;
        height: 13px;
      }
    }
    :deep(.dt-base-input),
    :deep(.dt-base-select) {
      margin-right: 0px !important;
    }
  }
}

.metree-wrap {
  width: 100%;
  height: 100%;
}
:deep(.step-num) {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  border: none;
  height: 24px;
  width: 102px;
  padding: 0;
  &::after {
    color: #000;
    content: "%";
    display: inline-block;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 34px;
    top: 0%;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #fff;
    box-sizing: border-box;
    width: 24px;
    height: 100%;
    top: 0;
    .el-icon {
      color: #000;
      font-weight: bold;
    }
    &:hover {
      background: #f0f4f8;
    }
  }
  .el-input-number__decrease {
    left: 0;
  }
  .el-input-number__increase {
    right: 0;
  }
  .el-input-number__decrease:hover
    ~ .el-input:not(.is-disabled)
    .el-input__wrapper,
  .el-input-number__increase:hover
    ~ .el-input:not(.is-disabled)
    .el-input__wrapper {
    box-shadow: none;
  }
  .el-input {
    height: 24px;
    .el-input__wrapper {
      box-shadow: none;
      padding: 0 47px 0 25px;
      .el-input__inner {
        height: 100%;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #000;
        text-align: right;
      }
    }
  }
}
</style>
