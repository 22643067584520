<template>
  <div class="cs-width-p100 cs-height-p100 cs-flex cs-relative drag-box">
    <div class="cs-width-p100 cs-height-p100 cs-flex cs-relative drag-box-inner">
      <vue-draggable-resizable class="cs-absolute drag-wrap" ref="dragPlugRef" :x="0" :y="initY">
        <div :style="{ transform: `scale(${scale})` }" class="tree-box cs-absolute">
          <mytree ref="mytreeref" :configure="tree" :lineStyle="lineStyle">
            <template v-slot="configure">
              <div class="tree-card-box cs-flex-col">
                <div class="tree-card-wrap cs-flex" :class="{
                    my: configure.hasme,
                    other: configure?.uids?.includes(uid),
                  }" @mouseenter="hoverEnter(configure)" @mouseleave="hoverLeave(configure)" @click="configure.expanded = !configure.expanded">
                  <div :class="[
                      'cs-flex tree-card',
                      `tree-level-${configure.level}`,
                    ]">
                    <el-popover placement="top-end" popper-class="tree-popover" :disabled="!grants.length">
                      <template #reference>
                        <div class="cs-height-p100 cs-flex tree-card-item">
                          {{ configure.name }}
                        </div>
                      </template>
                      <template #default>
                        <div class="cs-flex hover-btns">
                          <div class="user-btn btn" v-if="
                              grants.includes('user') &&
                              ['2', '3', '5', '6'].includes(configure.level)
                            " @click="handleFn(configure, 'user')"></div>
                          <div class="edit-btn btn" v-if="grants.includes('edit')" @click="handleFn(configure, 'edit')"></div>
                          <div class="del-btn btn" v-if="grants.includes('delete')" @click="handleFn(configure, 'del')"></div>
                          <div class="add-btn btn" v-if="
                              grants.includes('add') && +configure.level !== 7
                            " @click="handleFn(configure, 'add')"></div>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                  <div class="expand-wrap" :class="
                      numClassFn(configure.expanded, configure.children.length)
                    " @click.stop="configure.expanded = !configure.expanded" v-if="configure.children && configure.children.length">
                    <div class="expand-icon cs-flex" v-if="configure.hover && configure.expanded" @mouseenter="hoverEnter(configure)" @mouseleave="hoverLeave(configure)">
                      <img src="@/assets/imgs/tree/tree-right.png" alt="" />
                    </div>
                    <div class="expand-num cs-flex" v-if="!configure.expanded">
                      <span>{{ configure.children.length }}</span>
                    </div>
                  </div>
                  <div class="touxiang">
                    <img src="@/assets/imgs/tree/other.png" alt="" v-if="configure?.uids?.includes(uid)" />
                    <img src="@/assets/imgs/tree/my.png" alt="" v-else-if="configure.hasme" />
                  </div>
                </div>
              </div>
            </template>
          </mytree>
        </div>
      </vue-draggable-resizable>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, watch } from "vue";

import mytree from "./mtree";
import VueDraggableResizable from "vue-draggable-resizable";
import { deepClone } from "@darrytech/extends/utilities/ObjUtil";
export default defineComponent({
  name: "projectTreeMy",
  title: "树结构",
});
</script>
<script setup lang="ts">
import {
  inject,
  onMounted,
  reactive,
  ref,
  toRaw,
  onBeforeUnmount,
  shallowRef,
  nextTick,
  computed,
} from "vue";
const dragPlugRef = ref();
const scale = ref(1);
const grants = ref([]) as any;
const uid = ref("");
const lineStyle = {
  color: "#D0D3D6",
  width: 2,
};
const mytreeref = ref();
const initY = computed(() => {
  let y = 60;
  const htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const scale = htmlWidth / 1920;
  console.log(htmlWidth, scale, y * scale);
  return scale ? Math.ceil(y * scale) : 60;
});
watch(
  () => scale,
  (nval, oval) => mytreeref.value.resize()
);
const emits = defineEmits(["event"]);
const tree = ref({});
const setVal = (val) => {
  tree.value = val;
};
const setGrants = (val: any) => {
  grants.value = val;
};
const setIcon = (id?: any) => {
  uid.value = id || undefined;
};
const setScale = (val: any) => {
  scale.value = val / 100;
  dragPlugRef.value.left = 0;
  dragPlugRef.value.top = 0;
};
const handleFn = (curItem: any, type: string) => {
  let cur = deepClone(curItem);
  if (cur.children) delete cur.children;
  let param = {
    type: type,
    data: cur,
  };
  emits("event", param);
};
const hoverEnter = (configure: any) => {
  configure.hover = true;
};
const hoverLeave = (configure: any) => {
  configure.hover = false;
};
const numClassFn = (expanded, len) => {
  if (!expanded) return `expanded-no num_${len.toString().length}`;
  return "";
};
defineExpose({ setVal, setGrants, setIcon, setScale });
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.drag-box {
  overflow: hidden;
  .drag-box-inner {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.drag-wrap {
  width: 100% !important;
  height: 100% !important;
}
.tree-card-box {
  height: 64px;
  // padding-right: 13px;
}
.tree-card-wrap {
  position: relative;
  //   padding: 4px 4px 4px 0;
  padding: 4px 0px 4px 0;
  background: transparent;
  cursor: pointer;
  &.my {
    padding: 4px;
    background: #f3f7ff;
    box-shadow: 0px 0px 8px 0px rgba(44, 102, 255, 0.4);
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #2c66ff;
  }
  &.other {
    padding: 4px;
    background: #f3fffd;
    box-shadow: 0px 0px 8px 0px rgba(44, 255, 217, 0.4);
    border-radius: 10px 10px 10px 10px;
    border: 3px solid #0098ad;
  }
}
.tree-card {
  min-width: 42px;
  max-width: 280px;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  background: #e0e9ff;
  color: #2c66ff;
  .tree-card-item {
    max-width: 240px;
    padding: 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tree-level-2 {
  background: #000000;
  border: 1px solid rgba(44, 102, 255, 0.22);
  color: #ffffff;
}
.tree-level-3 {
  background: #2c66ff;
  color: #ffffff;
}
.expand-wrap {
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(-50%);
  &.num_1 {
    right: -23px;
  }
  &.num_2 {
    right: -30px;
  }
  &.num_3 {
    right: -36px;
  }
  &.num_4 {
    right: -44px;
  }
  &.num_5 {
    right: -51px;
  }
  &.num_6 {
    right: -59px;
  }
  .expand-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .expand-num {
    cursor: pointer;
    min-width: 6px;
    height: 19px;
    line-height: 19px;
    padding: 0px 6px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
      0px 2px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 28px 28px 28px 28px;
    border: 2px solid #2c66ff;
    font-size: 12px;
    color: #2c66ff;
  }
}
.touxiang {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
}
.hover-btns {
  height: 38px;
  padding: 0px;
  background: #1c243a;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  .btn {
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    &:nth-last-of-type(1) {
      border-right: none;
    }
    &:hover {
      background-color: #000000;
    }
  }
  .user-btn {
    background-image: url("@/assets/imgs/tree/user.png");
  }
  .edit-btn {
    background-image: url("@/assets/imgs/tree/edit.png");
  }
  .del-btn {
    background-image: url("@/assets/imgs/tree/del.png");
  }
  .add-btn {
    background-image: url("@/assets/imgs/tree/add.png");
  }
}
</style>
<style lang="scss">
.el-popper.tree-popover {
  padding: 0;
  width: auto !important;
  min-width: 0px;
  .el-popper__arrow {
    right: 15px !important;
    left: unset !important;
    &::before {
      background: #1c243a !important;
      border: none;
    }
  }
}
</style>
