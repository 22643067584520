<template>
  <div class="content-wrap" v-if="configure.template && usrinfo.token">
    <component :key="configure.name" :is="configure.template" ref="compageRef" :injectConfig="configure" @event="handler">
    </component>
    <examstart ref="examstartRef" @examClose="examClose"></examstart>
    <questionsimport ref="questionsimportRef" @examClose="questionsClose"></questionsimport>
    <!-- <receive ref="receiveRef" @examClose="receiveClose" :receiveForm="receiveForm"></receive> -->
    <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
    <!-- <myshenhe ref="myshenheRef" @refresh="shenheList"></myshenhe> -->
    <addressbookimport ref="addressbookimportRef" @examClose=addressbookClose />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "publicPage",
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.setConfig(to);
    });
  },
});
</script>

<script setup lang="ts">
import { inject, onMounted, reactive, ref } from "vue";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import { nextTick } from "process";
import { ElMessage } from "element-plus";
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const emits = defineEmits(["clickMenu"]);
const compageRef = ref();
const examstartRef = ref();
const confirmDiaRef = ref();
const myshenheRef = ref();
const questionsimportRef = ref();
const receiveRef = ref();
const configure = reactive({
  name: "",
  memo: "",
  template: "",
  module: "",
  configure: {},
  workflow: [],
  grants: [],
}) as any;

const receiveForm = ref<any>({});
const network: any = inject("$network");
const setConfig = (data: any) => {
  Object.assign(
    configure,
    {
      name: data.name,
      memo: "",
      template: "",
      module: "",
      configure: {},
      workflow: [],
      grants: [],
    },
    data.meta
  );
  console.log("configure==", configure);
};

const getHidden = () => {
  const hiddenArr = configure.configure?.hidden;
  if (hiddenArr && hiddenArr.length) {
    hiddenArr.forEach((item) => {
      network
        .wrapinvoke({
          module: "dtsystem",
          apiname: "infoDialogBox",
          apiparam: JSON.stringify({ name: item.name }),
        })
        .then((rsp: any) => {
          item.name = rsp.name;
          item.template = rsp.upload_file ? rsp.name : rsp.template;
          item.diacssname = `${rsp.name}`;
          item.title = rsp.title;
          item.module = rsp.module_name;
          item.upload_file = rsp.upload_file || "";
          item.configure = rsp.configure ? JSON.parse(rsp.configure) : {};
        })
        .catch((err: any) => {
          console.log(err);
        });
    });
  }
};
const detalis: any = ref({});
const type = ref(0);
const huiObj: any = ref({});
const addressbookimportRef = ref();
const dept_id = ref(0);
const offShelf: any = ref({});
const handler = (evt: any, ...args: any[]) => {
  // console.log("========evt", evt,args);
  if (evt.evttype == "examRecord:buttons:exam@click") {
    // 开始考试
    examstartRef.value.show();
  } else if (evt.evttype == "baseDept:table@gouserbtn") {
    // 单位管理
    dept_id.value = args[0]?.type;
    emits("clickMenu", {
      name: "userBasePage",
      query: { type: dept_id.value },
    });
  } else if (evt.evttype == "addressBook:table@gouserbtn") {
    emits("clickMenu", { name: "userBasePage" });
  } else if (evt.evttype == "examStore:buttons:import@click") {
    questionsimportRef.value.show();
  } else if (evt.evttype == "safetyReform:buttons:add@click") {
    // 获取当前日期
    const currentDate = new Date();
    // 加上31天
    currentDate.setDate(currentDate.getDate() + 31);
    // 转换为yyyy-mm-dd hh:mm:ss格式
    const formattedDate = currentDate
      .toISOString()
      .replace("T", " ")
      .substr(0, 19);
    nextTick(() => {
      compageRef.value
        .getreally()
        .getchild("adder")
        .getchild("reform_end_time")
        .setvalue(formattedDate);
    });
  } else if (evt.evttype == "baseNotice:table@participation") {
    const id = args[0].id;
    huiObj.value = { id: id, is_attend: 2 };
    confirmDiaRef.value.showFn({ msg: "确定参会吗？" });
    type.value = 1;
  } else if (evt.evttype == "baseNotice:table@refuse") {
    const id = args[0].id;
    huiObj.value = { id: id, is_attend: 1 };
    confirmDiaRef.value.showFn({ msg: "确定拒绝吗？" });
    type.value = 1;
  } else if (evt.evttype == "addressBook:buttons:import@click") {
    addressbookimportRef.value.show();
  }
  // else if (evt.evttype == "projectReport:table@offShelf") {
  //   confirmDiaRef.value.showFn({ msg: "确定下架吗？" });
  //   type.value = 2;
  //   detalis.value = args[0];
  //   console.log(args[0]);
  // offShelf.value = {id: args[0].id}
  // }
};

const sureDiaFn = async () => {
  if (type.value == 1) {
    // 会议拒收 或参加
    const result = await network.dteqms.editBaseNotice(huiObj.value);
    // 刷新当前页面
    network.dteqms.listBaseNotice({}).then((res: any) => {
      try {
        compageRef.value.getreally().getchild("table").setvalue(res);
      } catch (error) {}
    });
  } else if (type.value == 2) {
    // 下架
    const result = await network.dteqms.editOffShelfProjectReport(
      offShelf.value
    );
    // 刷新当前页面
    network.dteqms.listProjectReport({}).then((res: any) => {
      try {
        compageRef.value.getreally().getchild("table").setvalue(res);
      } catch (error) {}
    });
  }

  // 提示
  ElMessage({
    message: "操作成功",
    type: "success",
  });
};
const examClose = () => {
  // 刷新当前页面
  network.dteqms.listExamRecord({}).then((res: any) => {
    try {
      compageRef.value.getreally().getchild("table").setvalue(res);
    } catch (error) {}
  });
};

const questionsClose = () => {
  // 刷新当前页面
  network.dteqms.listExamStore({}).then((res: any) => {
    try {
      compageRef.value.getreally().getchild("table").setvalue(res);
    } catch (error) {}
  });
};

const receiveClose = () => {
  // 刷新当前页面
  network.dteqms.listSafetyReformAssign({}).then((res: any) => {
    try {
      compageRef.value.getreally().getchild("table").setvalue(res);
    } catch (error) {}
  });
};

const addressbookClose = () => {
  // 刷新当前页面
  network.dteqms.listAddressBook({}).then((res: any) => {
    try {
      compageRef.value.getreally().getchild("table").setvalue(res);
    } catch (error) {}
  });
};
onMounted(() => {
  getHidden();
});
defineExpose({ setConfig });
</script>

<style lang="scss" scoped>
.content-wrap {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 24px 35px;
}
</style>
