<template>
  <el-dialog draggable class="dt-dialog-inner receiveDialog" ref="dialogExamWrap" align-center v-model="dialogShow" width="30%">
    <template #header="{ titleClass }">
      <div :class="titleClass" class="dt-dialog-header cs-flex-bc">
        <div class="dialog-header-txt">{{ examTitle }}</div>
        <ElIcon class="cs-cursor-pointer cs-margin-5" @click.stop="hideFn">
          <Close />
        </ElIcon>
      </div>
    </template>
    <div>
      <template v-if="dialogShow">
        <div style="text-align: center" class="form">
          <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" label-position="top">
            <el-form-item label="接收人" prop="reform_uid">
              <el-select v-model="ruleForm.reform_uid" placeholder="请选择接收人">
                <el-option :label="item.name" :value="item.id" v-for="item in receiveList" :key="item.id" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <div class="cs-flex dt-insert-bar">
        <el-button @click="saveFn" type="primary">保存</el-button>
        <el-button @click="closeFn">取消</el-button>
      </div>
      <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
    </div>
  </el-dialog>
</template>
  <script lang="ts">
import * as XLSX from "xlsx";
import { defineComponent } from "vue";
export default defineComponent({
  name: "receive",
});
</script>
  <script lang="ts" setup>
import { ElMessage } from "element-plus";
import { ref, inject, onMounted } from "vue";
const network: any = inject("$network");
const examTitle = ref("问题整改接收");
const confirmDiaRef = ref();
const emtis = defineEmits(["examClose"]);
const questionSet = ref<any>("");
const FileList = ref<any>([]);
const dialogShow = ref(false);

const rules = {
  reform_uid: [{ required: true }],
};
// props接收
const props = defineProps({
  receiveForm: {
    type: Object,
    default: {},
  },
});

const ruleForm = ref<any>(props.receiveForm);

const receiveList = ref<any>([]);
const sureDiaFn = async () => {
  if (!ruleForm.value.reform_uid) {
    ElMessage({
      message: "请选择接收人",
      type: "warning",
    });
    return false;
  }
  const result: any = await network.dteqms.editSafetyReform(ruleForm.value);
  dialogShow.value = false;
  emtis("examClose");
};
// 获取接收人
const getReceive = async () => {
  const result: any = await network.dteqms.listUserByDept();
  receiveList.value = result.rows;
};
// 隐藏弹窗
const hideFn = () => {
  dialogShow.value = false;
  emtis("examClose");
};
// 显示弹窗
const show = () => {
  dialogShow.value = true;
  ruleForm.value.reform_status = 3;
  getReceive();
};
// 保存
const saveFn = () => {
  confirmDiaRef.value.showFn({ msg: "确定接收吗？" });
};
// 关闭弹窗
const closeFn = () => {
  FileList.value = [];
  questionSet.value = "";
  dialogShow.value = false;
};
onMounted(() => {
  console.log("onMounted");
});

defineExpose({ show });
</script>
  <style lang="scss">
.receiveDialog {
  width: 616px;
  .form {
    padding: 0 30px;
  }
}
</style>