//@ts-nocheck
import { PropType, computed, h, nextTick, reactive, withDirectives } from "vue";

export interface NodeConfig {
    id: String | Number, name: String, expanded: Boolean, children: NodeConfig[]
}
const defstyle = { color: 'rgba(255,0,0,1)', width: 1, cap: 'round' }
export default ({
    props: {
        configure: Object as PropType<NodeConfig>,
        margin: { type: Object as PropType<{}>, default: { x: 30, y: 30 } },
        lineStyle: { type: Object as PropType<{ color: String, width: Number, cap: String }>, default: defstyle }
    },
    slots: {
        default: () => <div></div>
    },
    methods: { resize() { this.$forceUpdate() } },
    mounted() {window.addEventListener('resize', this.resize) },
    unmounted() { window.removeEventListener('resize', this.resize) },
    setup(props, { attrs, slots, emit, expose }) {
        return ({ $props, $slots, $emit, $data, $options }) => {
            const arrays: any[] = [];
            const fromTo = (stylectx: any, f: { x: number, y: number }, t: { x: number, y: number }) => {
                const ctx = stylectx();
                ctx.beginPath();
                ctx.moveTo(parseInt(f.x), parseInt(f.y));
                ctx.lineTo(parseInt(t.x), parseInt(t.y));
                ctx.stroke();
                ctx.closePath();
            }
            const drawLine = (ctx: any, maxh:number,from: any, to: any) => {
                const { width: fw, height: fh } = from.getBoundingClientRect();
                const { offsetLeft: fx, offsetTop: fy } = from;
                const { width: tw, height: th } = to.getBoundingClientRect();
                const { offsetLeft: tx, offsetTop: ty } = to;
                const f = { x: fx + fw, y: fy + fh / 2 };
                const t = { x: tx, y: ty + th / 2 };
                if (Math.abs(f.y - t.y)<=maxh) fromTo(ctx, f, t);
                else {
                    const ms = { x: f.x + (t.x - f.x) / 2, y: f.y };
                    const me = { x: ms.x, y: t.y };
                    fromTo(ctx, f, ms); fromTo(ctx, ms, me); fromTo(ctx, me, t);
                }
            }
            const connect = (configure: any, consumer: any) => {
                const { id, name, expanded, children } = configure;
                if (!expanded || !children) { return; }
                const f = document.getElementById(id);
                children.forEach((v: any) => {
                    consumer(f, document.getElementById(v.id));
                    connect(v, consumer);
                });
            }
            const vResize = {
                
                updated(el, binding, vnode, prev) {
                    try {
                        const cvs = el.children[0];
                        const ctx = cvs.getContext('2d');
                        ctx.clearRect(0, 0, cvs.width, cvs.height);
                        const stylectx = () => {
                            ctx.lineCap = vnode.ctx.props.lineStyle.cap || defstyle.cap;
                            ctx.lineWidth = vnode.ctx.props.lineStyle.width || defstyle.width;
                            ctx.strokeStyle = vnode.ctx.props.lineStyle.color || defstyle.color;
                            return ctx;
                        }
                        const places: any = ({ maxh: 0, maxw: [], cols: 0, rows: 0 });
                        for (let idx = 1; idx < el.childNodes.length; idx++) {
                            const cnode = el.childNodes[idx];
                            const { width, height } = cnode.getBoundingClientRect();
                            const c = parseInt(cnode.getAttribute('c'));
                            const r = parseInt(cnode.getAttribute('r'));
                            places.cols = Math.max(places.cols, c);
                            places.rows = Math.max(places.rows, r);
                            places.maxw[c] = Math.max(width, places.maxw[c] || 0);
                            places.maxh = Math.max(height, places.maxh || 0);
                        }
                        for (let idx = 1; idx < el.childNodes.length; idx++) {
                            const cnode = el.childNodes[idx];
                            const { width, height } = cnode.getBoundingClientRect();
                            const c = parseInt(cnode.getAttribute('c'));
                            const r = parseInt(cnode.getAttribute('r'));
                            const top = (r * (places.maxh + $props.margin.y) + $props.margin.y);
                            let left = 0;
                            for (let idx = 0; idx < c; idx++)left += (places.maxw[idx] || 0);
                            const res = (left + (c * $props.margin.x) + $props.margin.x);
                            cnode.style.top = `${top}px`; cnode.style.left = `${res}px`;
                            if (places.cols === c) {
                                cvs.width = parseInt(res + width + $props.margin.x);
                                el.style.width = cvs.style.width = `${cvs.width}px`;
                            }
                            if (places.rows === r) {
                                cvs.height = parseInt(top + height + $props.margin.y);
                                el.style.height = cvs.style.height = `${cvs.height}px`;
                            }
                        }
                        connect(vnode.ctx.props.configure,  (f, t) => drawLine(stylectx,places.maxh, f, t));
                    } catch (error) {
                        
                    }
                   
                }
            }
            const degree = (configure, { c, r }) => {
                const { id, name, expanded, children } = configure;
                const style = { position: 'absolute' };
                arrays.push(<div id={id} c={c} r={r} style={style}>{$slots.default(configure)} </div>);
                if (!expanded || !children) return r;
                children.forEach((v: any, idx: number) => (r = degree(v, { c: c + 1, r: r + (idx > 0 ? 1 : 0) })));
                return r;
            }
            degree($props.configure, { c: 0, r: 0 });
            arrays.unshift(h('canvas', { style: { class: 'tree-canvas' } }));
            return withDirectives(h('div', { style: { position: 'relative' } }, arrays), [[vResize]]);
        }
    },
})
