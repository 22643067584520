<template>
  <div class="cs-width-p100 cs-height-p100">
    <div class="cs-flex-sc">
      <div class="dt-form-form">
        <div class="dt-form-form-wrap nomore">
          <el-date-picker
            v-model="timedata"
            class="dt-base-datepicker"
            :type="dataOptions.type"
            :range-separator="dataOptions.rangeReparator"
            :start-placeholder="dataOptions.startPlaceholder"
            :end-placeholder="dataOptions.endPlaceholder"
            :format="dataOptions.format"
            :value-format="dataOptions.format"
          />
        </div>
      </div>
      <el-button @click="queryFn" memo="查询">查询</el-button>
      <el-button @click="downLoad" memo="导出">导出</el-button>
    </div>

    <div class="file-wrap">
      <vue-office-docx
        :src="fileUrl"
        v-if="fileUrl && typeOffice == 1"
        style="width: 100%; height: 100%"
      />
      <vue-office-excel
        v-if="fileUrl && typeOffice == 2"
        :src="fileUrl"
        :options="excelOptions"
        style="width: 100%; height: 100%"
        @rendered="renderedHandler"
        @error="errorHandler"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, onBeforeUnmount } from "vue";
import { ElButton } from "element-plus";
export default defineComponent({
  name: "tongji",
  title: "统计组件",
});
</script>
<script setup lang="ts">
import VueOfficeDocx from "@vue-office/docx";
import "@vue-office/docx/lib/index.css";
import VueOfficeExcel from "@vue-office/excel";
import "@vue-office/excel/lib/index.css";
import * as Toast from "@darrytech/extends/utilities/toastutil";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import {
  inject,
  reactive,
  ref,
  shallowRef,
} from "vue";
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const network: any = inject("$network");
const emits = defineEmits(["getpage"]);
const props = defineProps({
  typeOffice: {
    type: Number,
    default: 0,
  },
  dataOptions: {
    type: Object,
    default: () => {
      return {
        format: "YYYY-MM",
        startPlaceholder: "请选择开始月份",
        endPlaceholder: "请选择结束月份",
        type: "monthrange",
        rangeReparator: "-",
      };
    },
  },
  excelOptions: {
    type: Object,
    default: () => {
      return {
        xls: false, //预览xlsx文件设为false；预览xls文件设为true
        // minColLength: 0, // excel最少渲染多少列，如果想实现xlsx文件内容有几列，就渲染几列，可以将此值设置为0.
        // minRowLength: 0, // excel最少渲染多少行，如果想实现根据xlsx实际函数渲染，可以将此值设置为0.
        widthOffset: 10, //如果渲染出来的结果感觉单元格宽度不够，可以在默认渲染的列表宽度上再加 Npx宽
        heightOffset: 10, //在默认渲染的列表高度上再加 Npx高
        beforeTransformData: (workbookData) => {
          return workbookData;
        }, //底层通过exceljs获取excel文件内容，通过该钩子函数，可以对获取的excel文件内容进行修改，比如某个单元格的数据显示不正确，可以在此自行修改每个单元格的value值。
        transformData: (workbookData) => {
          return workbookData;
        }, //将获取到的excel数据进行处理之后且渲染到页面之前，可通过transformData对即将渲染的数据及样式进行修改，此时每个单元格的text值就是即将渲染到页面上的内容
      };
    },
  },
});
const fileUrlInit = ref("");
const fileUrl = ref("");
const timedata = ref();
const queryFn = () => {
  //   let params = {
  //     start_time:
  //       timedata.value && timedata.value[0] ? timedata.value[0] : undefined,
  //     end_time:
  //       timedata.value && timedata.value[1] ? timedata.value[1] : undefined,
  //   };
  //   network.dteqms.expTrainCount().then((rsp: any) => {
  //     console.log(rsp);
  //     fileUrl.value = rsp.url;
  //     fileUrlInit.value = rsp.url;
  //   });
  emits("getpage", timedata.value);
};
const setUrl = (url: string) => {
  fileUrl.value = url;
  fileUrlInit.value = url;
};
const downLoad = () => {
  let arr = fileUrl.value.split("/");
  let a = document.createElement("a");
  a.style.display = "none";
  a.download = arr[arr.length - 1];
  a.href = `${fileUrl.value}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
const renderedHandler = () => {
  console.log("渲染完成");
};
const errorHandler = () => {
  console.log("渲染失败");
};
const tjResize = () => {
  if (usrinfo.current_router == "trainCountpage") {
    fileUrl.value = "";
    nextTick(() => {
      fileUrl.value = fileUrlInit.value;
    });
  }
};
onMounted(() => {
  window.addEventListener("resize", tjResize);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", tjResize);
});
defineExpose({
  fileUrlInit,
  fileUrl,
  setUrl,
});
</script>
<style lang="scss" scoped>
.dt-form-form {
  padding: 10px 0 10px;
}
.dt-base-datepicker {
  height: 40px;
  &.el-input__wrapper {
    height: 40px !important;
  }
}
</style>
<style lang="scss">
.file-wrap {
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden;
  overflow-y: auto;
  .vue-office-docx {
    width: 100%;
    .vue-office-docx-main {
      width: 100%;
      .docx-wrapper {
        background: #fff !important;
      }
    }
  }
  .vue-office-excel {
    width: 100%;
    .vue-office-excel-main {
      width: 100%;
      .x-spreadsheet {
        width: 100%;
        .x-spreadsheet-sheet {
          width: 100%;
        }
      }
    }
  }
}
</style>
