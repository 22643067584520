
import { Api } from '@/generate/v3doc';
import { ElMessage } from "element-plus";
import { BaseNetwork, createNetwork, keyword } from '@darrytech/extends/network';
import { inject } from 'vue';
import { UserInfo, forceUserInfo, userLogout } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import { Router, useRouter } from "vue-router";
import { installTestPages } from "./router";
import { isPhone } from "@darrytech/extends/utilities/sysenv";
export declare interface Network extends BaseNetwork, Api<any> { }
const defaultRequestSuc = (router: any) => {
  return (config: any) => {
    config.headers.state = 1; //不显示标记删除数据
    const userinfo: UserInfo = useStorage(Storages.USERINFO);
    config.headers.loginType = "web";
    config.headers.Authorization = userinfo.token!;
    config.headers.source = "web"; // isPhone() ? "app" : 
    if (!!userinfo && userinfo.project_id) config.headers.Currentprojectid = userinfo.project_id!; // 工程
    // f (!!userinfo) config.headers.Currentprojectid3 = userinfo.project_item_id || 0; // 项目 
    if (!!userinfo) config.headers.Currentitemid = userinfo.project_item_id || 0; // 项目
    if (!!userinfo && userinfo.id) config.headers.Currentuserid = userinfo.id!;
    if (!!userinfo && userinfo.dept_type) config.headers.Currentdepttype = userinfo.dept_type!;
    return config;
  };
};
const defaultResponseErr = (router: any) => {
  return (resp: any) => {
    const { status, config, data } = resp;
    if (status == 200) {
      if (data.errcode == 101 || data.errcode == 104) {
        // token过期了
        const userinfo: UserInfo = useStorage(Storages.USERINFO);
        userLogout(userinfo);
        router.push({ name: "page-login" });
      } else if (data.errcode !== 0) {
        ElMessage({
          message: data.errdesc,
          type: "warning",
        });
      }
    }
  };
};
export const network: any = createNetwork<Network>(new Api(), false);
network.wrapinvoke = (option: any) => {
  const input = !!option.apiparam ? JSON.parse(option.apiparam) : {};
  input.module____ = option.module;
  input.apiname____ = option.apiname;
  return network.dtsystem.dynamicInvoke(input);
};
export const NetworkAPI = (routerInit) => {
  return {
    install: async (Vue: any, options: any = {}) => {
      const router = await installTestPages(Vue, options, routerInit);
      network.useRequest(defaultRequestSuc(router));
      network.useResponse(defaultResponseErr(router));
      Vue.provide(keyword, network);
    },
  }
};
export const useNetwork = (): Network => inject(keyword) as Network;

