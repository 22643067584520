<template>
  <el-dialog draggable class="dt-dialog-inner questionsimportDialog" ref="dialogExamWrap" align-center v-model="dialogShow" width="30%">
    <template #header="{ titleClass }">
      <div :class="titleClass" class="dt-dialog-header cs-flex-bc">
        <div class="dialog-header-txt">{{ examTitle }}</div>
        <ElIcon class="cs-cursor-pointer cs-margin-5" @click.stop="hideFn">
          <Close />
        </ElIcon>
      </div>
    </template>
    <div>
      <template v-if="dialogShow">
        <div style="text-align: center" :class="FileList.length == 1 ? 'noUpload' : ''">
          <el-upload action="#" list-type="picture-card" :auto-upload="false" :limit="1" v-model:file-list="FileList" @change="handleUploadChange" accept=".xls,.xlsx">
            <el-icon>
              <Plus />
            </el-icon>
            <template #file="{ file }">
              <div class="uploadDiv">
                <el-icon class="iconUpload">
                  <Document />
                </el-icon>
                <span class="el-upload-list__item-actions">
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <el-icon>
                      <Delete />
                    </el-icon>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
        </div>
      </template>
      <div class="cs-flex dt-insert-bar">
        <el-button @click="saveFn" type="primary">保存</el-button>
        <el-button @click="closeFn">取消</el-button>
      </div>
      <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
    </div>
  </el-dialog>
</template>
  <script lang="ts">
import * as XLSX from "xlsx";
import { defineComponent } from "vue";
export default defineComponent({
  name: "addressbookimport",
});
</script>
  <script lang="ts" setup>
import {
  Delete,
  Download,
  Plus,
  ZoomIn,
  Close,
  Document,
} from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { ref, inject } from "vue";
import { json } from "node:stream/consumers";
const network: any = inject("$network");
const examTitle = ref("导入通讯录");
const disabled = ref(false);
const confirmDiaRef = ref();
const emtis = defineEmits(["examClose"]);
const questionSet = ref<any>("");
const FileList = ref<any>([]);
const dialogShow = ref(false);
const sureDiaFn = async () => {
  if (FileList.value.length == 0) {
    ElMessage({
      message: "请上传文件",
      type: "warning",
    });
    return false;
  }
  console.log(network.dteqms, "network.dteqms======");
  const result: any = await network.dteqms.importAddressBook({
    addressBookset: questionSet.value,
  });
  if (result) {
    ElMessage({
      message: result.msg,
      type: "warning",
    });
    return false;
  }

  FileList.value = [];
  questionSet.value = "";
  dialogShow.value = false;
  emtis("examClose");
};
// 隐藏弹窗
const hideFn = () => {
  dialogShow.value = false;
  emtis("examClose");
};
// 显示弹窗
const show = () => {
  dialogShow.value = true;
};
// 保存
const saveFn = () => {
  confirmDiaRef.value.showFn({ msg: "确定导入通讯录吗？" });
};
// 关闭弹窗
const closeFn = () => {
  FileList.value = [];
  questionSet.value = "";
  dialogShow.value = false;
};
// 删除文件
const handleRemove = (file: any) => {
  FileList.value = [];
};
const handleUploadChange = async (file: any, flist: any) => {
  if (file.raw.type == "application/json") {
    try {
      let resultNumArr: any = await readTextfile(file.raw, "UTF-8");
      let resArr = JSON.parse(resultNumArr).question;
      console.log("UTF-8", resultNumArr, typeof resultNumArr, resArr);
    } catch (error) {
      let resultNumArr: any = await readTextfile(file.raw, "GBK");
      let resArr = JSON.parse(resultNumArr).question;
      console.log("GBK", resultNumArr, resArr);
    }
  } else {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.raw);
    reader.onload = function (e: any) {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const wsname = workbook.SheetNames[0]; // 取第一张表
      const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
        raw: true,
      });
      let formattedArray: any = ws;
      let allKeys = new Set<string>(); // 指定 Set 中元素的类型为 string
      formattedArray.forEach((item) => {
        Object.keys(item).forEach((key) => allKeys.add(key)); // 使用 Set 的 add 方法
      });
      // 补全每个对象缺少的 key
      formattedArray = formattedArray.map((item) => {
        let newItem = { ...item };
        allKeys.forEach((key) => {
          if (!item.hasOwnProperty(key)) {
            newItem[key] = ""; // 或者你希望的默认值
          }
        });
        return newItem;
      });
      console.log("formattedArray==", formattedArray);
      questionSet.value = JSON.stringify(formattedArray);
    };
  }
};
const readTextfile = (raw: any, encode: string = "GBK") => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(raw, encode);
    reader.onloadend = function () {
      resolve(this.result);
    };
  });
};
defineExpose({ show });
</script>
  <style lang="scss">
.questionsimportDialog {
  width: 616px;
  .el-upload--picture-card {
    background: #fff;
  }
}
.el-upload-list {
  margin: 0 auto;
}
.noUpload {
  .el-upload {
    display: none;
  }
}
.iconUpload {
  font-size: 28px;
}
.uploadDiv {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>