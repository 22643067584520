<template>
  <!-- <dt-layout-dialog
    ref="dialogExamWrap"
    cssname="exam-start-dia"
    :title="exam-start-dia"
    @hide="hideFn"
  >
    <template v-slot:context="{ setSlotRef, getDialogRef }">
      <template v-if="dialogShow">
        <div v-if="!examStartShow" class="exam-start-wrap exam-change-wrap">
          <div @click="changeExamType(1)" class="day-type">
            <p class="title">每日一学</p>
            <p class="tip">别忘了完成今天的学习小任务哦!</p>
            <div class="btn">开始答题</div>
          </div>
          <div @click="changeExamType(2)" class="day-type normal-type">
            <p class="title">生活常识</p>
            <p class="tip">深入生活，查漏补缺!</p>
            <div class="btn">开始答题</div>
          </div>
        </div>

        <div class="exam-start-wrap cs-flex-bs" v-else>
          <div class="exam-left exam-result-left" v-if="examProcess == 2">
            <div class="exam-result-top cs-flex-col-sc">
              <div class="result-top-score">{{ resObj.totalScore }}</div>
              <p class="result-top-txt">我的得分</p>
              <div class="result-top-num">共{{ totalExam }}题</div>
              <p class="result-top-tip">点击右侧答题卡选项 查看答题情况</p>
            </div>
            <div class="exam-result-b cs-flex">
              <div class="exam-result-card card-time">
                <span></span>
                <div class="div-title">{{ yongshi }}</div>
                <p>答题用时</p>
              </div>
              <div class="exam-result-card card-rate">
                <span></span>
                <div class="cs-flex-bs">
                  <div class="cs-flex-col-ss">
                    <div class="div-title">{{ resObj.rate }}%</div>
                    <p>正确率</p>
                  </div>
                  <div class="pie-box">
                    <div ref="pieRef" class="pie-wrap"></div>
                  </div>
                </div>
              </div>
              <div class="exam-result-card card-truenum">
                <span></span>
                <div class="div-title">{{ resObj.trueCount }}</div>
                <p>正确题数</p>
              </div>
            </div>
          </div>
          <div class="exam-left" v-else>
            <div class="cs-flex-col-ss cs-height-p100">
              <div class="title cs-flex-sc">
                <span class="title-type"
                  >{{ currentNumber + 1 }}.{{ categoryTxt }}</span
                ><span class="title-num"
                  >(共{{ totalExam }}道题，合计{{ totalScore }}分)</span
                >
              </div>
              <div class="wenti cs-flex-1 cs-flex-col-ss">
                <transition name="fade" mode="out-in">
                  <template
                    v-if="
                      currentNumber >= 0 && currentNumber < questionList.length
                    "
                    key="current"
                  >
                    <examItem
                      ref="examItemRef"
                      :question="questionList[currentNumber]"
                      :isDisable="isDisable"
                      :currentNumber="currentNumber"
                      :totalExam="totalExam"
                      @chageRedio="chageRedio"
                      @chageCheck="chageCheck"
                      @chageShou="chageShou"
                    />
                  </template>
                </transition>
                <div class="ti-btns">
                  <el-button class="pre-btn" @click="preClick"
                    >上一题</el-button
                  >
                  <el-button class="next-btn" @click="nextClick"
                    >下一题</el-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="exam-right cs-flex-col-bc">
            <div class="exam-right-time cs-flex-col-bc">
              <div class="time-title cs-flex">
                <img src="@/assets/imgs/layout/dati-time.png" alt="" />
                <span>所用时间</span>
              </div>
              <div class="time-con cs-flex">
                {{ yongshi }}
              </div>
              <div class="time-process">
                <el-slider
                  v-model="sliderVal"
                  :min="0"
                  :max="totalExam"
                  :show-tooltip="false"
                  class="time-process-el"
                />
                <div class="time-process-mark" :style="{ left: markLeft }">
                  {{ daTiNum }}/{{ totalExam }}
                </div>
              </div>
            </div>
            <div class="exam-right-card cs-flex-col">
              <div class="card-title cs-flex">
                <img src="@/assets/imgs/layout/daticard.png" />
                <span>答题卡</span>
              </div>
              <div class="card-num cs-flex">
                <span>共{{ totalExam }}题</span><span class="fenge">|</span
                ><span>共{{ totalScore }}分</span>
              </div>
              <div
                class="card-con cs-flex"
                :class="{ 'card-con-disable': isDisable }"
              >
                <div
                  v-for="(item, index) in questionList"
                  class="daan cs-flex"
                  :class="cardClass(item)"
                  @click="examIndexClick(index)"
                >
                  <div class="daan-item">{{ index + 1 }}</div>
                </div>
              </div>
              <div class="card-ti-type cs-flex-bc" v-if="isDisable">
                <div class="cs-flex-col">
                  <span class="dui"></span><span>正确</span>
                </div>
                <div class="cs-flex-col">
                  <span class="cuo"></span><span>错误</span>
                </div>
                <div class="cs-flex-col">
                  <span class="weida"></span><span>未答</span>
                </div>
              </div>
              <div class="card-btn" @click="examSubmit" v-if="!isDisable">
                交卷
              </div>
              <div class="card-btn" @click="examResFn" v-else>查看结果</div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </dt-layout-dialog> -->
  <el-dialog
    draggable
    class="dt-dialog-inner"
    :class="[examStartShow ? 'exam-ing-dia' : 'exam-start-dia']"
    ref="dialogExamWrap"
    align-center
    v-model="dialogShow"
  >
    <template #header="{ titleClass }">
      <div :class="titleClass" class="dt-dialog-header cs-flex-bc">
        <div class="dialog-header-txt">{{ examTitle }}</div>
        <ElIcon class="cs-cursor-pointer cs-margin-5" @click.stop="hideFn">
          <Close />
        </ElIcon>
      </div>
    </template>
    <div>
      <template v-if="dialogShow">
        <div v-if="!examStartShow" class="exam-start-wrap exam-change-wrap">
          <div @click="changeExamType(1)" class="day-type">
            <p class="title">每日一学</p>
            <p class="tip">别忘了完成今天的学习小任务哦!</p>
            <div class="btn">开始答题</div>
          </div>
          <div @click="changeExamType(2)" class="day-type normal-type">
            <p class="title">生活常识</p>
            <p class="tip">深入生活，查漏补缺!</p>
            <div class="btn">开始答题</div>
          </div>
        </div>

        <div class="exam-start-wrap cs-flex-bs" v-else>
          <div class="exam-left exam-result-left" v-if="examProcess == 2">
            <div class="exam-result-top cs-flex-col-sc">
              <div class="result-top-score">{{ resObj.totalScore }}</div>
              <p class="result-top-txt">我的得分</p>
              <div class="result-top-num">共{{ totalExam }}题</div>
              <p class="result-top-tip">点击右侧答题卡选项 查看答题情况</p>
            </div>
            <div class="exam-result-b cs-flex">
              <div class="exam-result-card card-time">
                <span></span>
                <div class="div-title">{{ yongshi }}</div>
                <p>答题用时</p>
              </div>
              <div class="exam-result-card card-rate">
                <span></span>
                <div class="cs-flex-bs">
                  <div class="cs-flex-col-ss">
                    <div class="div-title">{{ resObj.rate }}%</div>
                    <p>正确率</p>
                  </div>
                  <div class="pie-box">
                    <div ref="pieRef" class="pie-wrap"></div>
                  </div>
                </div>
              </div>
              <div class="exam-result-card card-truenum">
                <span></span>
                <div class="div-title">{{ resObj.trueCount }}</div>
                <p>正确题数</p>
              </div>
            </div>
          </div>
          <div class="exam-left" v-else>
            <div class="cs-flex-col-ss cs-height-p100">
              <div class="title cs-flex-sc">
                <span class="title-type"
                  >{{ currentNumber + 1 }}.{{ categoryTxt }}</span
                ><span class="title-num"
                  >(共{{ totalExam }}道题，合计{{ totalScore }}分)</span
                >
              </div>
              <div class="wenti cs-flex-1 cs-flex-col-ss">
                <transition name="fade" mode="out-in">
                  <template
                    v-if="
                      currentNumber >= 0 && currentNumber < questionList.length
                    "
                    key="current"
                  >
                    <examItem
                      ref="examItemRef"
                      :question="questionList[currentNumber]"
                      :isDisable="isDisable"
                      :currentNumber="currentNumber"
                      :totalExam="totalExam"
                      @chageRedio="chageRedio"
                      @chageCheck="chageCheck"
                      @chageShou="chageShou"
                    />
                  </template>
                </transition>
                <div class="ti-btns">
                  <el-button class="pre-btn" @click="preClick"
                    >上一题</el-button
                  >
                  <el-button class="next-btn" @click="nextClick"
                    >下一题</el-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="exam-right cs-flex-col-bc">
            <div class="exam-right-time cs-flex-col-bc">
              <div class="time-title cs-flex">
                <img src="@/assets/imgs/layout/dati-time.png" alt="" />
                <span>所用时间</span>
              </div>
              <div class="time-con cs-flex">
                {{ yongshi }}
              </div>
              <div class="time-process">
                <el-slider
                  v-model="sliderVal"
                  :min="0"
                  :max="totalExam"
                  :show-tooltip="false"
                  class="time-process-el"
                />
                <div class="time-process-mark" :style="{ left: markLeft }">
                  {{ daTiNum }}/{{ totalExam }}
                </div>
              </div>
            </div>
            <div class="exam-right-card cs-flex-col">
              <div class="card-title cs-flex">
                <img src="@/assets/imgs/layout/daticard.png" />
                <span>答题卡</span>
              </div>
              <div class="card-num cs-flex">
                <span>共{{ totalExam }}题</span><span class="fenge">|</span
                ><span>共{{ totalScore }}分</span>
              </div>
              <div
                class="card-con cs-flex"
                :class="{ 'card-con-disable': isDisable }"
              >
                <div
                  v-for="(item, index) in questionList"
                  class="daan cs-flex"
                  :class="cardClass(item)"
                  @click="examIndexClick(index)"
                >
                  <div class="daan-item">{{ index + 1 }}</div>
                </div>
              </div>
              <div class="card-ti-type cs-flex-bc" v-if="isDisable">
                <div class="cs-flex-col">
                  <span class="dui"></span><span>正确</span>
                </div>
                <div class="cs-flex-col">
                  <span class="cuo"></span><span>错误</span>
                </div>
                <div class="cs-flex-col">
                  <span class="weida"></span><span>未答</span>
                </div>
              </div>
              <div class="card-btn" @click="examSubmit" v-if="!isDisable">
                交卷
              </div>
              <div class="card-btn" @click="examResFn" v-else>查看结果</div>
            </div>
          </div>
        </div>
      </template>
      <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
    </div>
  </el-dialog>
  <!-- <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia> -->
</template>
<script lang="ts">
import * as echarts from "echarts";
import { Close } from "@element-plus/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "examstart",
});
</script>
<script setup lang="ts">
import examItem from "./examItem.vue";
import confirmDia from "@/comps/confirmDia/index.vue";

import {
  inject,
  onMounted,
  reactive,
  ref,
  computed,
  nextTick,
  onBeforeUnmount,
} from "vue";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const emtis = defineEmits(["examClose"])
const network: any = inject("$network");
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const confirmDiaRef = ref();
const dialogExamWrap = ref();
const dialogShow = ref(false);
const dialogExamType = ref(1); // 1选择考试类型2开始考试3考完了
const examTitle = ref("请选择考试类型");
const examStartShow = ref(false);
const examStartType = ref(); //1,每日一学2,生活常识
const totalExam = ref(0);
const totalScore = ref(0);
const questionList = ref([]) as any;
const numberArr = ["A.", "B.", "C.", "D.", "E.", "F."];
const currentNumber = ref(0); // 当前第几道

const examItemRef = ref();
const isDisable = ref(false);
const sliderVal = ref(0);
const markLeft = ref("0%"); // (10 / 44) * 100 - 5 + "%";
const timerId = ref(null);
const testTime = ref(0); //s
const resObj = reactive({
  rate: "",
  totalScore: "",
  trueCount: "",
});
const examProcess = ref(0);
const pieRef = ref();
const mypiechart = ref();
const categoryTxt = computed(() => {
  let type = questionList.value[currentNumber.value].category;
  let txt = type === 1 ? `选择题` : type === 2 ? `判断题` : "";
  return txt;
});
const show = () => {
  // dialogExamWrap.value.show();
  dialogShow.value = true;
};
const hideFn = () => {
  if (dialogExamType.value == 2) {
    confirmDiaRef.value.showFn({ msg: "关闭后答题无效,确定关闭吗？" });
  } else {
    dialogShow.value = false;
    resetFn();
    emtis("examClose");
  }
};
const sureDiaFn = () => {
  dialogShow.value = false;
  resetFn();
  emtis("examClose");
  
};
const resetFn = () => {
  stopTimer();
  examStartShow.value = false;
  examTitle.value = "请选择考试类型";
  dialogExamType.value = 1;
  examStartShow.value = false;
  examStartType.value = undefined;
  totalExam.value = 0;
  totalScore.value = 0;
  questionList.value.slice(0, questionList.value.length);
  currentNumber.value = 0;
  isDisable.value = false;
  sliderVal.value = 0;
  markLeft.value = "0%";
  testTime.value = 0;
  examProcess.value = 0;
};
const changeExamType = async (type: number) => {
  examStartType.value = type;
  // 获取考题
  const result: any = await network.dteqms.examQuestionQuery({ type: type });
  totalExam.value = result?.cnt;
  totalScore.value = result.total_score;
  let datas1 = [];
  result.questionList?.forEach((each: any) => {
    let rightAnswer = each.rate_answer.split(",");
    // each.category_type = rightAnswer.length > 1 ? 3 : each.category; //1选择2判断
    const options: any = [];
    each.option.forEach((element: any, idx: any) => {
      if (element.length > 2) {
        options.push({
          content: element,
          checked: false,
          // number: numberArr[idx],
        });
      }
    });
    each.options = options;
    datas1.push(each);
  });
  questionList.value = [...datas1] || [];

  examStartShow.value = true;
  examTitle.value = "开始考试";
  dialogExamType.value = 2;
  examProcess.value = 1;
  // 开始倒计时
  startTimer();
};
const chageShou = async (val: any) => {
  // 收藏题
  if (val == 1) {
    await network.dteqms.addCollectStoreMe({
      type: 1,
      es_id: questionList.value[currentNumber.value].id + "",
    });
  } else {
    await network.dteqms.deleteCollectStoreMe({
      type: 1,
      es_id: questionList.value[currentNumber.value].id + "",
    });
  }
  questionList.value[currentNumber.value].collect = val;
};
const chageRedio = (val: any) => {
  questionList.value[currentNumber.value].options.forEach((v, i) => {
    if (i == val) {
      v.checked = true;
    } else {
      v.checked = false;
    }
  });
  questionList.value[currentNumber.value].answer = val + 1;
};
const chageCheck = (val: any) => {
  questionList.value[currentNumber.value].options.forEach((v, i) => {
    if (val.includes(i)) {
      v.checked = true;
    } else {
      v.checked = false;
    }
  });
  questionList.value[currentNumber.value].answer = val
    .map((v) => v + 1)
    .join(",");
};
const nextClick = () => {
  if (currentNumber.value < questionList.value.length - 1)
    currentNumber.value++;
  examItemRef.value.setAnswer();
};
const preClick = () => {
  if (currentNumber.value > 0) currentNumber.value--;
  examItemRef.value.setAnswer();
};
const examIndexClick = (i: any) => {
  examProcess.value = 1; //
  currentNumber.value = i;
  nextTick(() => {
    examItemRef.value.setAnswer();
  });
};
const examSubmit = async () => {
  stopTimer();
  let params: any = {};
  params.type = examStartType.value;
  params.datas = questionList.value;
  const result: any = await network.dteqms.examResult({
    questionSet: JSON.stringify(params),
  });
  questionList.value.slice(0, questionList.value.length);
  questionList.value = result.questionList;
  currentNumber.value = 0;
  isDisable.value = true;
  examItemRef.value.setAnswer();
  // 返得多少分、正确率、答对几道
  resObj.rate = result.rate;
  resObj.totalScore = result.totalScore;
  resObj.trueCount = result.trueCount;
  examTitle.value = "考试结果";
  dialogExamType.value = 3;
  examProcess.value = 2;
  nextTick(() => {
    setPie();
  });
};
const examClose = () => {
  hideFn();
  // dialogExamWrap.value.hide();
};
const examResFn = () => {
  examProcess.value = 2;
  nextTick(() => {
    setPie();
  });
};
const cardClass = (item: any) => {
  if (item.answer && !isDisable.value) {
    return "daan-wan";
  } else if (isDisable.value && item.answer) {
    let type = item.category;
    if (type == 1) {
      let answerArr = item.rate_answer.split(",").map((v) => v - 1);
      let checkLen = item.options.filter((v, index) => v.checked);
      let duiLen = item.options.filter((v, index) => {
        return v.checked && answerArr.includes(index);
      });

      if (
        checkLen.length == answerArr.length &&
        duiLen.length == answerArr.length
      ) {
        return "daan-dui";
      } else {
        return "daan-cuo";
      }
    } else {
      let arr = item.options.filter((v, index) => {
        return v.checked && index == item.rate_answer - 1;
      });
      if (arr.length) {
        return "daan-dui";
      } else {
        return "daan-cuo";
      }
    }
  }
};
const daTiNum = computed(() => {
  let arr = questionList.value.filter((v) => v.answer);
  let len = arr?.length || 0;
  sliderVal.value = len;
  markLeft.value = (len / totalExam.value) * 100 - 5 + "%";
  return len;
});
const yongshi = computed(() => {
  return sTohms(testTime.value);
});

const startTimer = () => {
  if (!timerId.value) {
    timerId.value = setInterval(() => {
      testTime.value++;
    }, 1000);
  }
};

const stopTimer = () => {
  if (timerId.value) {
    clearInterval(timerId.value);
    timerId.value = null;
  }
};
const sTohms = (value: any) => {
  let second = parseInt(value); //  秒
  let minute = 0; //  分
  let hour = 0; //  时
  if (second > 59) {
    minute = parseInt(second / 60);
    second = parseInt(second % 60);
    if (minute > 59) {
      hour = parseInt(minute / 60);
      minute = parseInt(minute % 60);
      if (hour < 10) hour = "0" + hour; //小时补零
    }
  }
  if (second < 10) second = "0" + second; //秒数补零
  if (minute < 10) minute = "0" + minute; //分钟补零
  let result = "" + second;
  result = "" + minute + ":" + result;
  if (hour > 0) {
    result = "" + hour + ":" + result;
  }
  return result;
};
const setPieOptions = () => {
  let pieOption = {
    tooltip: {
      trigger: "item",
      show: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: "正确率",
        type: "pie",
        radius: ["70%", "90%"],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: resObj.rate,
            name: "正确率",
            itemStyle: { color: "rgba(255, 134, 0, 1)" },
          },
          {
            value: 100 - resObj.rate,
            name: "错误率",
            itemStyle: { color: "#FFECD8" },
          },
        ],
      },
    ],
  };
  mypiechart.value.setOption(pieOption);
};
const setPie = () => {
  mypiechart.value = echarts.init(pieRef.value);
  window.addEventListener("resize", chartsResize);
  setPieOptions();
};
const chartsResize = () => {
  mypiechart.value.resize();
};
onMounted(() => {});
onBeforeUnmount(() => {
  window.removeEventListener("resize", chartsResize);
});
defineExpose({ show });
</script>
<style lang="scss" scoped>
.exam-start-wrap {
  width: calc(1210px + 336px + 18px);
  height: 800px;
  background: rgba(247, 248, 251, 1);
  padding: 0px 20px 20px;
  .exam-left {
    width: 1210px;
    height: 100%;
    .title {
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      padding: 26px 40px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(0, 90, 234, 0.06);
      border-radius: 18px 18px 0 0;
      margin-bottom: 1px;
      .title-type {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 20px;
        color: #316eff;
        line-height: 23px;
      }
      .title-num {
        font-size: 16px;
        color: #9b9aa3;
        margin-left: 10px;
      }
    }
    .wenti {
      box-sizing: border-box;
      width: 100%;
      padding: 36px 40px 40px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(0, 90, 234, 0.06);
      border-radius: 0 0 18px 18px;
      .wenti-item {
        position: relative;
        width: 100%;
        .question {
          font-size: 18px;
          color: #000000;
          line-height: 21px;
          padding-bottom: 36px;
        }
        .answer {
          // height: 500px;
          .answer-group {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 1000px;

            &-item {
              box-sizing: border-box;
              width: 100%;
              height: 54px;
              background: #f6f9ff;
              border-radius: 8px 8px 8px 8px;
              border: 2px solid #d6e3f5;
              padding: 0 15px;
              font-size: 18px;
              margin-bottom: 24px;

              .el-radio__label {
                color: #000;
              }
              &.is-checked {
                border: 2px solid #2c66ff;
                .el-radio__label {
                  color: #2c66ff;
                }
              }
            }
          }
        }

        .shoucang {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 25px;
          height: 24px;
          background: url("@/assets/imgs/layout/shoucang.png") no-repeat 0 0;
          background-size: 25px 24px;
        }
        .collect-cur {
          background: url("@/assets/imgs/layout/shoucang-cur.png") no-repeat 0 0;
          background-size: 25px 24px;
        }
      }

      .ti-btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .pre-btn,
        .next-btn {
          width: 118px;
          height: 36px;
          border-radius: 6px 6px 6px 6px;
        }
        .next-btn {
          background: #316eff;
          color: #fff;
          border: 1px solid #316eff;
        }
        .pre-btn {
          background: #fff;
          color: rgba(0, 0, 0, 0.85);
          border: 1px solid #dcdcdc;
        }
      }
    }
  }
  .exam-right {
    width: 336px;
    height: 100%;
    .exam-right-time,
    .exam-right-card {
      width: 100%;
      height: 240px;
      background: #fff;
      border-radius: 12px;
    }
    .exam-right-card {
      box-sizing: border-box;
      flex: 1;
      margin-top: 17px;
      padding: 28px 24px 40px;
      .card-title {
        img {
          width: 21px;
          height: 20px;
        }
        span {
          font-size: 20px;
          color: #000000;
          padding-left: 4px;
        }
      }
      .card-num {
        padding: 28px 0 24px;
        span {
          font-size: 16px;
          color: #9b9aa3;
        }
        .fenge {
          padding: 0 28px;
        }
      }
      .card-con {
        flex: 1;
        padding: 0 1px 0 16px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        overflow: hidden;
        overflow-y: auto;

        .daan {
          width: 38px;
          height: 38px;
          margin-right: 14px;
          margin-bottom: 14px;
          border-radius: 8px 8px 8px 8px;
          background: #fff;
          border: 1px solid #9b9aa3;
          font-size: 16px;
          color: #677885;
        }
        .daan-wan {
          background: linear-gradient(90deg, #eef3ff 0%, #ffffff 100%);
          border: 1px solid #2c66ff;
          color: #2c66ff;
        }

        &.card-con-disable {
          .daan {
            background: #f7f7f7;
            border: 1px solid #f7f7f7;
            color: #677885;
          }
          .daan-cuo {
            background: linear-gradient(90deg, #ffeded 0%, #ffffff 100%);
            border: 1px solid #ff3636;
            color: #ff3636;
          }
          .daan-dui {
            background: linear-gradient(90deg, #eefff5 0%, #ffffff 100%);
            border: 1px solid #00ad45;
            color: #00ad45;
          }
        }
      }
      .card-ti-type {
        box-sizing: border-box;
        width: 100%;
        padding: 18px 20px;
        span {
          font-size: 16px;
          color: #677885;
        }
        .dui,
        .cuo,
        .weida {
          width: 18px;
          height: 18px;
          border-radius: 12px;
          margin-bottom: 8px;
        }
        .dui {
          background: linear-gradient(90deg, #eefff5 0%, #ffffff 100%);
          border: 2px solid #00ad45;
        }
        .cuo {
          background: linear-gradient(90deg, #ffeded 0%, #ffffff 100%);
          border: 2px solid #ff3636;
        }
        .weida {
          background: #f7f7f7;
          border: 2px solid #f7f7f7;
        }
      }
      .card-btn {
        width: 100%;
        text-align: center;
        height: 38px;
        line-height: 38px;
        background: #316eff;
        border-radius: 6px;
        color: #fff;
      }
    }
    .exam-right-time {
      box-sizing: border-box;
      padding: 28px 40px;
      .time-title {
        img {
          width: 21px;
          height: 19px;
        }
        span {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
          padding-left: 4px;
        }
      }
      .time-con {
        width: 100%;
        height: 72px;
        background: #f6f9ff;
        border-radius: 8px 8px 8px 8px;
        font-family: "DIN-Bold";
        font-size: 36px;
        color: #016bff;
      }
      .time-process {
        width: 100%;
        position: relative;
        :deep(.time-process-el) {
          .el-slider__runway {
            height: 12px;
            background-color: #f6f9ff;
            border-radius: 8px;
            .el-slider__bar {
              height: 12px;
              background-color: #016bff;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            .el-slider__button-wrapper {
              top: -12px;
              .el-slider__button {
                width: 18px;
                height: 18px;
                background-color: #016bff;
                border: none;
              }
            }
          }
        }
        .time-process-mark {
          position: absolute;
          top: -10px;
          font-family: "DIN-Bold";
          font-size: 14px;
          color: #016bff;
        }
      }
    }
  }
  .exam-result-left {
    width: 1210px;
    height: 100%;
    border-radius: 12px;
    background: url("@/assets/imgs/layout/exam-res-bg.png") no-repeat center
      center;
    background-size: 100%;
    overflow: hidden;
    .exam-result-top {
      width: 100%;
      padding-top: 190px;
      .result-top-score {
        font-weight: bold;
        font-size: 100px;
        color: #316eff;
        line-height: 100px;
      }
      .result-top-txt {
        font-size: 16px;
        color: #333333;
        padding-top: 1px;
      }
      .result-top-num {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.4);
        padding-top: 12px;
      }
      .result-top-tip {
        font-size: 14px;
        color: rgba(51, 51, 51, 0.4);
        padding-top: 57px;
      }
    }
    .exam-result-b {
      padding-top: 130px;
    }
    .exam-result-card {
      box-sizing: border-box;
      width: 280px;
      height: 125px;
      border-radius: 0 0 8px 8px;
      position: relative;
      span {
        position: absolute;
        top: 0;
        left: -1px;
        right: -1px;
        display: block;
        height: 4px;
      }
      .div-title {
        padding: 30px 0 8px 20px;
        font-size: 28px;
        font-weight: bold;
        line-height: 33px;
      }
      p {
        padding-left: 20px;
        font-size: 16px;
        line-height: 19px;
      }
      &.card-time {
        background: linear-gradient(180deg, #eef3ff 0%, #ffffff 100%);
        border: 1px solid rgba(44, 102, 255, 0.22);
        span {
          background: #2c66ff;
        }
        div {
          color: #2c66ff;
        }
        p {
          color: #2c66ff;
        }
      }
      &.card-rate {
        background: linear-gradient(180deg, #fff1e1 0%, #ffffff 100%);
        border: 1px solid rgba(255, 134, 0, 0.22);
        margin: 0 20px;
        span {
          background: #ff8600;
        }
        div {
          color: #ff8600;
        }
        p {
          color: #ff8600;
        }
      }
      &.card-truenum {
        background: linear-gradient(180deg, #e5fff0 0%, #ffffff 100%);
        border: 1px solid rgba(0, 173, 69, 0.22);
        span {
          background: #00ad45;
        }
        div {
          color: #00ad45;
        }
        p {
          color: #00ad45;
        }
      }
    }
  }
}
.exam-change-wrap {
  height: 400px;
  width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .day-type {
    box-sizing: border-box;
    height: 173px;
    width: 456px;
    padding: 24px 0 0 30px;
    background: url("@/assets/imgs/layout/exam-type1.png") no-repeat 0 0;
    background-size: 456px 173px;
    margin-bottom: 24px;
    cursor: pointer;
    .title {
      font-weight: bold;
      font-size: 24px;
      color: #001240;
      line-height: 28px;
      padding-bottom: 8px;
    }
    .tip {
      font-weight: 500;
      font-size: 18px;
      color: #07233a;
      line-height: 21px;
      padding-bottom: 30px;
    }
    .btn {
      width: 92px;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      border-radius: 56px 56px 56px 56px;
      font-size: 16px;
      color: #0f2b74;
      text-align: center;
    }
  }
  .normal-type {
    background: url("@/assets/imgs/layout/exam-type2.png") no-repeat 0 0;
    background-size: 456px 173px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.pie-box {
  padding-top: 40px;
  padding-right: 20px;
}
.pie-wrap {
  width: 50px;
  height: 50px;
}
</style>
<style lang="scss">
.exam-start-dia,
.exam-ing-dia {
  width: 616px;
  .el-dialog__header .dt-dialog-header {
    background: #f7f8fb;
  }
  .el-dialog__body {
    background: #f7f8fb;
  }
  .confirm-normal{
    .el-dialog__header .dt-dialog-header {
    background: #fff;
  }
  }
}
.exam-ing-dia {
  width: calc(1210px + 336px + 18px + 40px);
 
}
</style>
