export declare interface Meta {
    name?: string, 
    memo?: string,
    template?: string, 
    module?: string, 
    configure?: {},
    workflow?: [],
    grants?: []
}
export const createMetaInfo = (vue: any, options: any): Meta => {
    const metastring = sessionStorage.getItem('meta');
    const meta = {};
    if (!!metastring) Object.assign(meta, JSON.parse(metastring));
    return meta;
}
export const forceMetaInfo = (meta: Meta) => {
    sessionStorage.setItem('meta', JSON.stringify(meta));
}
