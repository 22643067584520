<template>
  <dt-layout-dialog
    ref="dialogMyshenheWrap"
    cssname="my-shenhe-dia"
    title="审核"
    @hide="hideFn"
  >
    <template v-slot:context="{ setSlotRef, getDialogRef }">
      <div class="my-shenhe-wrap cs-flex-col-sc">
        <div class="cs-flex-col-ss">
          <span class="title">审核意见</span>
          <el-input
            type="textarea"
            v-model="val"
            class="shenheval"
            :autosize="true"
          ></el-input>
        </div>
        <div class="dia-self-btns cs-flex">
          <el-button @click.stop="sureFn" type="primary">同意</el-button>
          <el-button @click.stop="rejectFn">驳回</el-button>
        </div>
      </div>
    </template>
  </dt-layout-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "myshenhe",
});
</script>
<script setup lang="ts">
import { inject, onMounted, reactive, ref, computed } from "vue";
import * as Toast from "@darrytech/extends/utilities/toastutil";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const emits = defineEmits(["refresh"]);
const network: any = inject("$network");
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const dialogMyshenheWrap = ref();
const val = ref("");
const curObj = ref();
const show = (param: any) => {
  dialogMyshenheWrap.value.show();
  curObj.value = param;
};
const hideFn = () => {
  val.value = "";
};
const sureFn = () => {
  setAuditingFn(3);
};
const rejectFn = () => {
  setAuditingFn(4);
};
const setAuditingFn = (state: any) => {
  network.dteqms.checkAuditingDual({
      check_state: state,
      check_advise: val.value,
      id: curObj.value.id,
      table_name: curObj.value.table_name,
    })
    .then(() => {
      Toast.info("审核成功！");
      dialogMyshenheWrap.value.hide();
      hideFn();
    });
};
defineExpose({ show });
</script>
<style lang="scss">
.my-shenhe-dia {
  .my-shenhe-wrap {
    width: 800px;
    background: #fff;
    padding: 10px 30px 30px;
    .title {
      padding-bottom: 10px;
    }
    .shenheval {
      width: 800px;
      height: auto;

      .el-textarea__inner {
        min-height: 100px !important;
      }
    }
    .dia-self-btns {
      padding-top: 30px;
    }
  }
}
</style>
