import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  NavigationGuardNext,
} from "vue-router";
import { inject } from "vue";
import { UserInfo, forceUserInfo, userLogout } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";

const compages: any = import.meta.globEager("@/pages/compage/**/index.vue");

const createBefore = (user?: any) => {
  return (to: any, from: any, next: NavigationGuardNext) => {
    const usrinfo: UserInfo = useStorage(Storages.USERINFO);
    usrinfo.current_router = to.name;

    if (!usrinfo.token && to.name != "page-login") {
      next({ name: "page-login" });
    }
    if (to.fullPath == "/") {
      next({ path: "/indexPage" });
    }
    next();
  };
};

//@ts-ignore
const basicpages: any = import.meta.globEager("@/pages/basics/**/index.vue");
const logicpages: any = import.meta.globEager("@/pages/logics/**/index.vue");

export const installTestPages = async (
  Vue: any,
  options: any = {},
  router: any
): Promise<any> => {
  // const router = createRouter({ history: createWebHashHistory(), routes: [] });
  for (const path in basicpages) {
    const component: any = basicpages[path].default;
    if (!!component.name && component.name !== "main")
      router.addRoute({
        name: component.name,
        path: `/${component.name}`,
        component,
      } as any);
  }
  for (const path in logicpages) {
    const component = logicpages[path].default as any;
    router.addRoute("main", {
      name: component.name,
      path: "/" + component.name,
      component,
      meta: { memo: component.title, keepAlive: false },
    } as any);
  }
  const usrinfo: UserInfo = useStorage(Storages.USERINFO);
  router.beforeEach(createBefore());
  Vue.use(router);
  return router;
};
