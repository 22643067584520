<template>
  <el-tabs
    v-model="editableTabsValue"
    type="card"
    class="tabs-wrap"
    closable
    @tab-remove="removeTab"
    @tab-click="onTap"
  >
    <el-tab-pane
      v-for="item in editableTabs"
      :key="item.name"
      :label="item.title"
      :name="item.name"
    >
    </el-tab-pane>
  </el-tabs>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Ref,
  inject,
} from "vue";
import { Router, useRouter, useRoute, Route } from "vue-router";

export default defineComponent({
  name: "main-toptab",
  title: "工作区面包屑导航",
});
</script>
<script setup lang="ts">
import { UserInfo, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const router: Router = useRouter();
const route: Route = useRoute();
const emits = defineEmits(["clickMenu"]);
const editableTabsValue = computed({
  get() {
    return usrinfo.current_router;
  },
  set(newValue) {
    usrinfo.current_router = newValue;
    forceUserInfo(usrinfo);
    emits("clickMenu", {name:newValue});
  },
});
const editableTabs = computed(() => {
  return usrinfo.tabs_router || [];
});

const removeTab = (targetName: string) => {
  if (targetName == "indexPage") return;
  let allRouter = router.getRoutes();
  allRouter.forEach((item:any) => {
    if (item.name == targetName) item.meta.keepAlive++;
  });
  const tabs = editableTabs.value;
  let activeName = editableTabsValue.value;
  if (activeName === targetName) {
    tabs.forEach((tab, index) => {
      if (tab.name === targetName) {
        const nextTab = tabs[index + 1] || tabs[index - 1];
        if (nextTab) {
          activeName = nextTab.name;
        } else {
          activeName = "indexPage"; // 默认展示的页签
        }
      }
    });
  }
  usrinfo.current_router = activeName;
  let len = tabs.length;
  usrinfo.tabs_router =
    len == 1
      ? [{ title: "首页", name: "indexPage" }]
      : tabs.filter((tab) => tab.name !== targetName);
  forceUserInfo(usrinfo);
  emits("clickMenu", {name: activeName});
};
const onTap = (item: any, index: number) => {};
</script>

<style lang="scss">
.tabs-wrap {
  height: 100%;
  --el-border-color-light: rgba(203, 203, 203, 0.53);
  .el-tabs__header {
    margin: 0px;
    height: 100% !important;
    border-bottom: none !important;
    .el-tabs__nav-wrap {
      margin: 0px;
      height: 100%;
      .el-tabs__nav-prev {
        border-right: 2px solid rgba(203, 203, 203, 0.53);
      }
      .el-tabs__nav-scroll {
        height: 100%;
        .el-tabs__nav {
          height: 100%;
          border: none;
          .el-tabs__item {
            box-sizing: border-box;
            height: 100%;
            padding: 0 18px;
            font-size: 16px;
            color: #000000;
            border-left: 2px solid rgba(203, 203, 203, 0.53);
            box-shadow: 1px 0px 0px 0px rgba(255,255,255,0.15);
            &:nth-of-type(1){
              border-left: none;
            }
            &:nth-last-of-type(1) {
              border-right: 2px solid rgba(203, 203, 203, 0.53);
            }

            &.is-active {
              background: #fff;
              border-radius: 17px 17px 0 0;
              border-left: none;
              border-right: none;
              position: relative;
              &::before{
                position:absolute;
                bottom:-1px;
                left:-18px;
                content:'';
                width: 18px;
                height: 18px;
                background: url("@/assets/imgs/layout/tabLeft.png") no-repeat center center;
                background-size: 18px 18px;
              }
              &::after{
                position:absolute;
                bottom:-1px;
                right:-18px;
                content:'';
                width: 18px;
                height: 18px;
                background: url("@/assets/imgs/layout/tabRight.png") no-repeat center center;
                background-size: 18px 18px;
              }
              & + .el-tabs__item {
                border-left: none;
              }
            }
            .is-icon-close {
              width: 16px;
              height: 16px;
              color: #888;
            }
            &:nth-of-type(1) {
              .is-icon-close {
                display: none;
              }
            }
            &:hover{
              .is-icon-close {
                background: #BEC8E3;
                
              }
            }
          }
        }
      }
    }
  }
  .el-tabs__content {
    display: none;
  }
}
</style>
