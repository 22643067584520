import { deepClone, deepMerge, isArray, isFunction } from '@darrytech/extends/utilities/ObjUtil';
export declare interface TabsRouter {
    name?: string,
    title?: string,
    keepAlive?: boolean
}
export declare interface StringOrNumberArray {
    [index: number]: string | number;
}
export declare interface UserInfo {
    token?: string,
    id?: number,
    login_name?: string,
    name?: string,
    userGrants?: {},
    current_router?: string,
    tabs_router?: TabsRouter[],
    menuData?:[],
    models?:StringOrNumberArray,
    project_id?: string|undefined,
    project_name?: string|undefined,
    projects?:[],
    project_item_id?: string|number|undefined,
    avatar?:string,
    gender?:number,
    weatherBg?:string,
    temperature?:string|number,
    dept_type?:string|number
}

export const createUserInfo = (vue: any, options: any): UserInfo => {
    const userstring = sessionStorage.getItem('user');
    const user = {};   
    if (!!userstring){ return deepMerge(JSON.parse(userstring), user);}         
    return user;
}
export const forceUserInfo = (usrinfo: UserInfo) => {
    sessionStorage.setItem('user', JSON.stringify(usrinfo));

}
export const userLogout = (usrinfo: UserInfo) => {
    usrinfo.id = 0;
    usrinfo.token = undefined;
    usrinfo.name = "";   
    usrinfo.login_name = "";
    usrinfo.userGrants= {};
    usrinfo.tabs_router = [];
    usrinfo.models = [];
    usrinfo.menuData = [];
    usrinfo.current_router = "page-login";
    sessionStorage.clear()
  }
  