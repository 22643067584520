<template>
  <div>
    <dt-layout-dialog ref="addProjectTreeDia" :title="diaTitle" cssname="mind-dia" @hide="diaHide">
      <template v-slot:context="{ }">
        <div class="jsmind-menu-form">
          <div class="jsmind-form">
            <div class="form-item">
              <p><span>*</span><span>{{diaName}}</span></p>
              <el-input v-model="formData.name" class="dt-base-input"></el-input>
            </div>
            <div class="form-item">
              <p><span>*</span><span>编码</span></p>
              <el-input v-model="formData.code" class="dt-base-input"></el-input>
            </div>
          </div>
          <div class="cs-flex dt-insert-bar">
            <el-button @click="saveFn" type="primary">保存</el-button>
            <el-button @click="closeFn">取消</el-button>
          </div>
        </div>
      </template>
    </dt-layout-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "projectTreeTAdd",
  title: "新增编辑",
});
</script>
<script setup lang="ts">
import * as Toast from "@darrytech/extends/utilities/toastutil";
import {
  inject,
  onMounted,
  reactive,
  ref,
  toRaw,
  onBeforeUnmount,
  shallowRef,
  nextTick,
} from "vue";
const emits = defineEmits(["submitForm"]);
const network: any = inject("$network");
const props = defineProps({
  diaTitle: { type: String, default: "" },
  diaName: { type: String, default: "" },
});
const type = ref();
const curJie = reactive({
  id: undefined,
  level: undefined,
  name: undefined,
  pid: undefined,
  code: undefined,
});
const addProjectTreeDia = ref();
const formData = reactive({
  name: "",
  code: "",
});
const saveFn = () => {
  if (!formData.name) {
    Toast.error(`名称是必填项！`);
    return false;
  }
  if (!formData.code) {
    Toast.error(`编码是必填项！`);
    return false;
  }
  if (type.value == "add") {
    saveAddFn();
  } else if (type.value == "edit") {
    saveEditFn();
  }
};
const saveAddFn = () => {
  let param: any = {};
  param.pid = curJie.id;
  param.project_id = undefined;
  param.level = Number(curJie.level) + 1;
  param.name = formData.name;
  param.code = formData.code;
  addFn(param);
};
const addFn = (param: any) => {
  network.dteqms.saveOrUpdateNode(param).then((rsp: any) => {
    Toast.info("新增成功!");
    closeFn();
    emits("submitForm");
  });
};
const saveEditFn = () => {
  let param: any = {};
  param.pid = curJie.pid;
  param.project_id = curJie.id;
  param.level = Number(curJie.level);
  param.name = formData.name;
  param.code = formData.code;
  editFn(param);
};
const editFn = (param: any) => {
  network.dteqms.saveOrUpdateNode(param).then((rsp: any) => {
    Toast.info("编辑成功!");
    closeFn();
    emits("submitForm");
  });
};
const closeFn = () => {
  addProjectTreeDia.value.hide();
  diaHide();
};
const diaHide = () => {
  Object.assign(formData, { name: "", code: "" });
};
const getCodeName = () => {
  network.dteqms.infoBaseProject2({ id: curJie.id }).then((rsp: any) => {
    Object.assign(formData, { name: rsp.name, code: rsp.code });
    addProjectTreeDia.value.show();
  });
};
const show = (param: any) => {
  type.value = param.type;
  Object.assign(
    curJie,
    {
      id: undefined,
      level: undefined,
      name: undefined,
      pid: undefined,
    },
    param.data
  );
  if (param.type == "edit") {
    getCodeName();
  } else {
    Object.assign(formData, { name: "", code: "" });
    addProjectTreeDia.value.show();
  }
};
defineExpose({ show });
</script>
<style lang="scss">
.mind-dia {
  .el-dialog__body {
    .dt-dialog-header {
      width: auto;
    }
    .jsmind-menu-form {
      width: 410px;
    }
  }
}
</style>
<style lang="scss" scoped>
.jsmind-menu-form {
  background: #fff;
  .btn {
    padding: 10px;
    background: #2c66ff;
    border-radius: 4px;
    margin-right: 30px;
    font-size: 16px;
    text-align: center;
  }
  .jsmind-form {
    padding: 0px 0px 0px 40px;
    display: flex;
    flex-wrap: wrap;
    .form-item {
      margin-bottom: 30px;
      &:nth-of-type(2) {
        padding-right: 0;
      }
      p {
        margin-bottom: 10px;
        span {
          font-size: 16px;
        }
      }
    }
    .dt-base-input,
    .dt-base-select {
      width: 337px;
      height: 42px;
      margin-right: 40px;
      --el-disabled-bg-color: #122412;
      --el-disabled-border-color: #3b5a84;
      --el-disabled-text-color: #e1f0e1;

      .el-input__wrapper,
      .el-select__wrapper {
        box-sizing: border-box;
        width: 335px;
        height: 40px;
        background: #fff;
        border: 1px solid #cdd4df;
        box-shadow: none;
        border-radius: 4px;

        &.is-hovering:not(.is-foscued) {
          border: 1px solid #cdd4df;
          box-shadow: none;
        }
      }

      .el-input__inner {
        font-size: 18px;
        color: #000;
        -webkit-text-fill-color: #000;
        font-family: PingFang SC;

        &::placeholder {
          color: #9fafbc;
        }
      }
    }
  }
  .dt-insert-bar {
    padding-left: 0;
    width: auto;
  }
}
</style>
