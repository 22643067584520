<template>
  <div class="main-left">
    <div class="menu-bar">
      <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :unique-opened="true"
        @select="handleSelect"
        @open="openMemu"
        @close="closeMenu"
      >
        <LeftSubMenu
          :menuData="menuData"
          :openFirst="openFirst"
          v-if="menuData.length"
        ></LeftSubMenu>
      </el-menu>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Ref,
  inject,
  watch,
} from "vue";
import { Router, useRouter } from "vue-router";

export default defineComponent({
  name: "main-left",
  title: "工作区左侧菜单栏",
});
</script>
<script lang="ts" setup>
import { useAssets } from "@/cores/jsexts/resource";
import LeftSubMenu from "@/pages/basics/main/LeftSubMenu.vue";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";

const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const { VITE_MINIO_URL } = import.meta.env;
const router: Router = useRouter();
const emits = defineEmits(["clickMenu", "subMenuOpen", "subMenuClose"]);
const network: any = inject("$network");
const menuData: Ref<any[]> = ref([]);
const initMenuData: Ref<any[]> = ref([]);
const openFirst = ref();
const firstIcon = ref([
  useAssets("imgs/layout/dtsystem.png"),
  useAssets("imgs/layout/dtsystem.png"),
]);
console.log(firstIcon, "firstIcon");
const treeData = (source, id, parentId, children) => {
  let cloneData = JSON.parse(JSON.stringify(source));

  let resArr = cloneData.filter((father) => {
    let branchArr = cloneData.filter((child) => father[id] == child[parentId]);

    branchArr.length > 0 ? (father[children] = branchArr) : "";
    return father[parentId] == 0; // 如果第一层不是parentId=0，请自行修改
  });
  return resArr.filter((item) => item[children] && item[children].length); //过滤掉没有二级菜单的
};
const handleSelect = (index: any) => {
  emits("clickMenu", index);
};
const openMemu = (index: any) => {
  openFirst.value = index;
  emits("subMenuOpen");
};
const closeMenu = () => {
  openFirst.value = "";
  emits("subMenuClose");
};
const onRoutes = computed(() => {
  return usrinfo.current_router;
});
const noRouter = [
  "module_funct",
  "funModule",
  "funSelf",
  "funInterface",
  "projectConfig",
  "page-login",
  "diaPage",
  "baseProject",
  // "baseProject1",
  // "SafetyUserCount",
  // "trainCountpage",
  // "rectificationPage",
  // "helpCheckCountPage",
  // "workSignInCount",
];

const getMenu = () => {
  //清理一下之前的
  network.dtsystem.getFunctDate({ id: 2 }).then((rsp: any) => {
    if (!rsp || rsp?.errcode) return;
    let res: any = rsp;
    initMenuData.value = rsp;
    getMenuReset();
  });
};
const getMenuReset = () => {
  let res = initMenuData.value;
  console.log("res==", res);
  menuData.value = [];
  let testRouter: any = [];
  let firstparent: any = [];
  let allArr: any = [];
  // 根据model过滤掉一部分
  // usrinfo.userGrants['117'].push('exporttrain') // 统计的导出测试
  const youRouter = Object.keys(usrinfo.userGrants || {});
  res?.forEach((item) => {
    if (item.name !== "module_funct") {
      firstparent.push({
        idkey: `${item.group_id}`,
        name: item.english_name, // 分组一级菜单
        english_name: 0, //parent_id
        path: item.english_name,
        memo: item.group_name || "系统模块",
        icon: `${VITE_MINIO_URL}${item.default_icon}` || firstIcon.value[0],
        iconCur: `${VITE_MINIO_URL}${item.icon}` || firstIcon.value[1],
      });
      if (youRouter.includes(item.id + "")) {
        allArr.push(item);
      }
    }
    if (!noRouter.includes(item.name)) {
      let grants = (usrinfo?.userGrants && usrinfo?.userGrants[item.id]) || [];
      let curG = JSON.parse(item.grants);
      let resGrants =
        curG?.filter((item3) =>{ 
          if(item3.name.includes('P_')){item3.name=item3.name.substring(2)}
          return grants.includes(item3.name);          
        }) || [];

      testRouter.push({
        name: item.name,
        meta: {
          module: item.module_name,
          template: item.template,
          memo: item.memo,
          configure: JSON.parse(item.configure),
          workflow: JSON.parse(item.workflow),
          grants: resGrants,
          keepAlive: 0,
        },
      });
    }
  });

  let firstparentArr = [
    ...new Map(firstparent.map((item) => [item.name, item])).values(),
  ];
  getRouter(testRouter);
  menuData.value = treeData(
    [...firstparentArr, ...allArr],
    "name",
    "english_name",
    "children"
  );
};

const getRouter = (testRouter) => {
  const compages: any = import.meta.globEager("@/pages/compage/**/index.vue");
  let compageInfo = compages["/source/pages/compage/index.vue"].default as any;
  testRouter?.forEach((item) => {
    compageInfo.name = item.name;
    router.addRoute("main", {
      name: item.name,
      path: "/" + item.name,
      component: compageInfo,
      meta: item.meta,
    } as any);
  });
  usrinfo.tabs_router = [];
  // let allRouter = router.getRoutes();
  // allRouter.forEach((item) => {
  //   item.meta.keepAlive++;
  // });
  handleSelect("indexPage");
 
};
onMounted(() => {
  if (usrinfo.token) getMenu();
});
defineExpose({
  getMenu,
  getMenuReset,
});
</script>

<style lang="scss" scoped>
.main-left {
  box-sizing: border-box;
  width: 240px;
  height: 100%;
  background: url("@/assets/imgs/layout/left-bg.png") no-repeat center bottom;
  background-size: 240px auto;
  overflow: hidden;
  .menu-bar {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
      background-color: transparent;
      border-radius: 20px;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.1);
      border-radius: 20px;
    }
    :deep(.el-menu) {
      box-sizing: border-box;
      background-color: transparent;
      font-size: 14px;
      color: #ffffff;
      border-right: none;
      width: 100%;
      padding-left: 14px;
    }
  }
}
</style>
