import { Meta, createMetaInfo } from "./meta";
import { createUserInfo, UserInfo } from "./user";
import { defineStore } from 'pinia';
export enum Storages {
    METAINFO = 'Meta',
    USERINFO = 'UserInfo',
}
export const useStorage = <T>(name: string, value?: T, force: boolean = false): T => {
    const store = defineStore(name, function (): any {
        return { ref: undefined };
    })();
    if (!!value) if (force || !store.ref) store.ref = value;
    return store.ref;
}
export const initStorages = () => {
    return {
        install: (vue: any, options?: any) => {
            useStorage<Meta>(Storages.METAINFO, createMetaInfo(vue, options));
            useStorage<UserInfo>(Storages.USERINFO, createUserInfo(vue, options));           
        }
    }
}