<template>
  <div v-if="usrinfo.current_router == 'page-login'">
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>
  <div class="app-wrap" v-else>
    <layoutPage></layoutPage>
  </div>
</template>
<script setup lang="ts">
import layoutPage from "./pages/basics/main/index.vue";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
</script>
<style lang="scss" scoped>
.app-wrap {
  width: 100vw;
  height: 100vh;
  font-family: PingFang SC;
}
</style>
<style lang="scss">
@font-face {
  font-family: "DingTalk-JinBuTi";
  src: url("@/assets/font/DINGTALK JINBUTI.TTF") format("truetype");
}
@font-face {
  font-family: "FZLanTingHei-Medium";
  src: url("@/assets/font/FZLanTingHei-Medium.TTF") format("truetype");
}
@font-face {
  font-family: "FZLanTingHei-Regular";
  src: url("@/assets/font/FZLanTingHei-Regular.TTF") format("truetype");
}
@font-face {
  font-family: "PingFang SC";
  src: url("@/assets/font/PINGFANG REGULAR.TTF") format("truetype");
}
@font-face {
  font-family: "PingFang-Medium";
  src: url("@/assets/font/PINGFANG MEDIUM.TTF") format("truetype");
}
@font-face {
  font-family: "PingFang-Bold";
  src: url("@/assets/font/PINGFANG BOLD.TTF") format("truetype");
}
@font-face {
  font-family: "DIN-Bold";
  src: url("@/assets/font/DIN-BOLD.OTF") format("truetype");
}
@font-face {
  font-family: "DIN-Medium";
  src: url("@/assets/font/DIN-MEDIUM.OTF") format("truetype");
}
@font-face {
  font-family: "ZHKHFST";
  src: url("@/assets/font/ZHKHFST.TTF") format("truetype");
}
</style>
