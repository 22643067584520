<template>
  <div class="wenti-item cs-flex-1 cs-flex-col-ss">
    <div class="question">{{ question.question }}</div>
    <div class="answer cs-flex-1" v-if="question.options">
      <el-checkbox-group
        v-if="question.category == 1"
        v-model="checkList"
        class="answer-group"
        :disabled="isDisable"
        @change="checkChange"
      >
        <el-checkbox
          class="answer-group-item"
          :class="checkClass(item, index)"
          v-for="(item, index) in question.options"
          :label="index"
          :value="index"
          :key="index"
          >{{ item.content }}</el-checkbox
        >
      </el-checkbox-group>
      <el-radio-group
        v-else
        class="answer-group"
        v-model="answerIndex"
        @change="radioChange"
        :key="question.id"
        :disabled="isDisable"
      >
        <el-radio
          class="answer-group-item"
          v-for="(item, index) in question.options"
          :class="radioClass(item, index)"
          :label="index"
          :value="index"
          :key="index"
          >{{ item.content }}</el-radio
        >
      </el-radio-group>
    </div>
    <div
      class="shoucang"
      :class="[question.collect == 1 && 'collect-cur']"
      @click="shouFn"
    ></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "examstartitem",
});
</script>
<script setup lang="ts">
import { inject, onMounted, reactive, ref, nextTick } from "vue";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
import { UserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const emits = defineEmits(["chageRedio", "chageCheck", "chageShou"]);
const network: any = inject("$network");
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const numberArr = ["A.", "B.", "C.", "D.", "E.", "F."];
const props = defineProps({
  question: {
    type: Object,
    default: () => {},
  },
  isDisable: {
    type: Boolean,
    default: false,
  },
  currentNumber: {
    type: Number,
    default: 0,
  },
  totalExam: {
    type: Number,
  },
});
const answerIndex = ref();
const checkList = ref([]) as any;

const radioChange = (val) => {
  emits("chageRedio", val);
};
const checkChange = (val: any) => {
  emits("chageCheck", val);
};
const setAnswer = () => {
  answerIndex.value = undefined;
  checkList.value = [];
  let iArr:any = [];
  nextTick(() => {
    console.log('props.question==', props.question)
    props.question.options.forEach((v:any, i:any) => {
      if (v.checked) iArr.push(i);
    });
    if (props.question.category == 1) {
      checkList.value = [...iArr];
    } else {
      answerIndex.value = iArr.length ? iArr[0] : undefined; // 单选题
    }
  });
};
const shouFn = () => {
  // 收藏题
  let val = props.question.collect == 1 ? 0 : 1;
  emits("chageShou", val);
};
const checkClass = (item, i) => {
  if (props.isDisable) {
    let rightAnswer = props.question.rate_answer.split(",").map((v) => v - 1);
    let curItem = props.question.options[i];
    if (curItem?.checked && rightAnswer.includes(i)) {
      return "answer-dui";
    } else if (curItem?.checked && !rightAnswer.includes(i)) {
      return "answer-cuo";
    } else if (rightAnswer.includes(i)) {
      return "answer-dui";
    }
  }
};
const radioClass = (item, i) => {
  if (props.isDisable) {
    let rightAnswer = props.question.rate_answer - 1;
    let curItem = props.question.options[i];
    if (curItem?.checked && rightAnswer == i) {
      return "answer-dui";
    } else if (curItem?.checked && rightAnswer !== i) {
      return "answer-cuo";
    } else if (rightAnswer == i) {
      return "answer-dui";
    }
  }
};
defineExpose({ setAnswer });
</script>
<style lang="scss" scoped>
.wenti-item {
  position: relative;
  width: 100%;
  .question {
    font-size: 18px;
    color: #000000;
    line-height: 21px;
    padding-bottom: 36px;
    width: 1000px;
  }
  .answer {
    // height: 500px;
    .answer-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1000px;

      &-item {
        box-sizing: border-box;
        width: 100%;
        height: 54px;
        background: #f7f7f7;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #f7f7f7;
        padding: 0 15px;
        font-size: 18px;
        margin-bottom: 24px;

        .el-radio__label,
        .el-checkbox__label {
          color: #000 !important;
        }
        &:hover {
          background: #EDEDED;
        }
        &.is-checked {
          border: 1px solid #2c66ff;
          background: linear-gradient(90deg, #eef3ff 0%, #ffffff 100%);
          .el-radio__label,
          .el-checkbox__label {
            color: #2c66ff;
          }
        }
      }
      :deep(.answer-dui) {
        border: 1px solid #00ad45;
        background: linear-gradient(90deg, #eefff5 0%, #ffffff 100%);
        &.is-disabled {
          .el-radio__label,
          .el-checkbox__label {
            color: #00ad45 !important;
          }

          .el-checkbox__input .el-checkbox__inner {
            border: 1px solid #00ad45;
            background: #fff;
            &::after {
              border-color: #00ad45;
            }
          }
          .el-radio__input.is-disabled .el-radio__inner {
            border-color: #00ad45;
            background-color: #fff;
            &::after {
              background-color: #fff;
            }
          }
          .el-radio__input.is-disabled.is-checked .el-radio__inner {
            border-color: #00ad45;
            background-color: #fff;
            &::after {
              background-color: #00ad45;
            }
          }
        }
      }
      :deep(.answer-cuo) {
        border: 1px solid #ff3636;
        background: linear-gradient(90deg, #ffeded 0%, #ffffff 100%);
        &.is-disabled {
          .el-radio__label,
          .el-checkbox__label {
            color: #ff3636 !important;
          }
          .el-checkbox__input .el-checkbox__inner {
            border: 1px solid #ff3636;
            background: #fff;
            &::after {
              border-color: #ff3636;
            }
          }
        }
        .el-radio__input.is-disabled .el-radio__inner {
          border-color: #ff3636;
          background-color: #fff;
          &::after {
            background-color: #fff;
          }
        }
        .el-radio__input.is-disabled.is-checked .el-radio__inner {
          border-color: #ff3636;
          background-color: #fff;
          &::after {
            background-color: #ff3636;
          }
        }
      }
    }
  }

  .shoucang {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 25px;
    height: 24px;
    background: url("@/assets/imgs/layout/shoucang.png") no-repeat 0 0;
    background-size: 25px 24px;
  }
  .collect-cur {
    background: url("@/assets/imgs/layout/shoucang-cur.png") no-repeat 0 0;
    background-size: 25px 24px;
  }
}
</style>
