<template>
  <div>
    <dt-base-tree :injectConfig="treeConfig" ref="authDiaTreeRef" @event="treeSubmit"></dt-base-tree>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "authDia",
  title: "授权弹框",
});
</script>
<script setup lang="ts">
import * as Toast from "@darrytech/extends/utilities/toastutil";
import {
  inject,
  onMounted,
  reactive,
  ref,
  toRaw,
  onBeforeUnmount,
  shallowRef,
  nextTick,
} from "vue";
import { UserInfo, userLogout, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const emits = defineEmits(["submitForm"]);
const network: any = inject("$network");
const authDiaTreeRef = ref();
const treeConfig = reactive({
  usedAlone: true,
});
const treeSubmit = (val: any) => {
  if (val.evttype == "@treeHide") {
  }
  if (val.evttype == "@treeSubmit") {
    let treeVal = authDiaTreeRef.value.getvalue();
    emits("submitForm", treeVal);
  }
};
const getTreeData = () => {
  network
    .wrapinvoke({
      module: "dtsystem",
      apiname: "listQuoteFunct",
      apiparam: JSON.stringify({
        module_id: 61, //民用机场
        pageIndex: 1,
        pageSize: 9999,
      }),
    })
    .then((rsp: any) => {
      authDiaTreeRef.value.setTreeData(rsp.rows);
    });
};
const show = (param: any) => {
  nextTick(() => {
    authDiaTreeRef.value.setvalue(param);
  });
};
onMounted(() => {
  if (usrinfo.token && usrinfo.current_router == "baseProject") getTreeData();
});
defineExpose({ show });
</script>
<style lang="scss" scoped>
.auth-dia-wrap {
  background: #fff;
  width: 876px;
}
</style>
