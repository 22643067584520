
const extcompos: any = import.meta.globEager("@/comps/**/index.vue");
const foreachComps = (consumer: (page: any) => void) => {
    for (const path in extcompos) {
        const component = extcompos[path].default as any;
        consumer && consumer(component.name, component);
    }
}
export const installExtends = (Vue: any, options: any = {}): void => {
    foreachComps(Vue.component);
    console.log('darrytech[ext components] install ok!');
};