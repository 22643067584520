import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHashHistory } from 'vue-router';
import { createPinia } from "pinia";
import ElementUI from "element-plus";
import locale from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/theme-chalk/index.css";
import "@darrytech/element/style.css";
import "./styleMain.scss";
import "./assets/font/iconfont.css";
import { NetworkAPI } from "./cores/network";
import { initStorages } from "./cores/stores";
import { installComponents } from "@darrytech/element";
import { installTestPages } from "./cores/router/index";
import { installRemotes } from "./cores/plugin";
import { installExtends } from './comps';

(async function async() {
  const router = createRouter({ history: createWebHashHistory(), routes: [] });
  const app = createApp(App).use(ElementUI, { locale }).provide('$rxrouter', router).use(createPinia());
  await app.use(NetworkAPI(router));
  app
    .use(initStorages())
    .use(installComponents())
    .use(installRemotes)
    .use(installExtends)
    .mount("#app");
})();
