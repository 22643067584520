//@ts-ignore
import * as Vue from 'vue';
import {loadModule} from "vue3-sfc-loader/dist/vue3-sfc-loader"
import { network } from "@/cores/network";

const { VITE_MINIO_URL } = import.meta.env;
const createOptions = () => {
    return {
        moduleCache:{vue:Vue},
        getFile: async (url: string) => {
            const res = await fetch(url);
            if (!res.ok) throw Object.assign(new Error(res.statusText + ' ' + url), { res });
            return { getContentData:async (asBinary: any) => asBinary ? await res.arrayBuffer() : await res.text() }
        },
        addStyle: (textContent: string) => {
            const style = Object.assign(document.createElement('style'), { textContent });
            const ref = document.head.getElementsByTagName('style')[0] || null;
            document.head.insertBefore(style, ref);
        }
    }
}
export const remoteComponents = async ( ...urls: string[]): Promise<any> => {
    const options: any = createOptions();
    const components: any[] = [];
    for (let idx = 0; idx < urls.length; idx++){
        components.push(await loadModule(urls[idx], options))
    };
    return (Vue: any, options: any = {}) => components.forEach((c: any) => Vue.component(c.name, c));
}
export const getRemotes = async () => {
  let remotes: any = [];
  let r:any = await network.wrapinvoke({
    module: "dtsystem",
    apiname: "listDialogBox",
    apiparam: JSON.stringify({
      pageIndex: 1,
      pageSize: 9999,
      module_id: 61
    }),
  });
  let arr1 = r?.rows?.filter((v) => v.upload_file) || [];
  let arr2 = arr1?.map((v2) => `${VITE_MINIO_URL}${v2.upload_file}`) || [];
  remotes = arr2;
 
  return remotes;
};
export const installRemotes = async (app: any, options: any = {}): void => {
   let remotes:any = await getRemotes() || [];
   let useRemotes = await remoteComponents( ...remotes)
   app.use(useRemotes);
  };
